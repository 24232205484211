import React, { useEffect, useState } from "react"
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MdDashboard, MdPeople, MdSettings, MdOutlineSubtitles, MdSpaceDashboard, MdOutlineNetworkCheck } from "react-icons/md";
import { LuUserCircle2 } from "react-icons/lu";
import { useSelector } from "react-redux";
import PermissionDenied from "../../components/PermissionDenied"
import { RiBuilding4Line, RiSimCardLine } from "react-icons/ri";
import Sidebar from "../../components/Sidebar";
import DashboardComp from "../../components/DashboardComp";
import Settings from "./Settings";
import { primaryColor } from "../../constant";
import Technicians from "./Technicians";
import CreateTechnicians from "../../components/CreateTechnicians";
import Users from "./Users";
import CreateUsers from "../../components/CreateUsers";
import SimCards from "./SimCards";
import Networks from "./Networks";
import { FaWallet } from "react-icons/fa";
import { GrTransaction } from "react-icons/gr";
import Wallet from "./Wallet";
import WalletTransaction from "./WalletTransaction";


export default function Dashboard() {
    const location = useLocation();
    const isDesktop = useMediaQuery('(min-width: 768px)');
    const { user_data } = useSelector(state => state.user);
    const [permissions, setPermissions] = useState([])

    function getPermissionKeys(arr) {
        return arr?.map(permission => permission?.permission_key);
    }

    useEffect(() => {
        const sub_admin_permissions = getPermissionKeys(user_data?.permissions)
        if (sub_admin_permissions) {
            setPermissions(sub_admin_permissions)
        }
    }, [user_data])

    let current_path = window.location.pathname;

    const dashboard_items = [
        {
            title: 'Dashboard',
            link: '/admin/dashboard',
            icon: <MdSpaceDashboard style={{ color: location.pathname === '/admin/dashboard' ? primaryColor : '#757575' }} />,
        },
        {
            title: 'SIM Cards',
            link: '/admin/dashboard/sim-cards',
            icon: <RiSimCardLine style={{ color: location.pathname === '/admin/dashboard/sim-cards' ? primaryColor : '#757575' }} />,
        },
        {
            title: 'Technicians',
            link: '/admin/dashboard/technicians',
            icon: <LuUserCircle2 style={{ color: location.pathname === '/admin/dashboard/technicians' ? primaryColor : '#757575' }} />,
        },
        {
            title: 'Users',
            link: '/admin/dashboard/users',
            icon: <LuUserCircle2 style={{ color: location.pathname === '/admin/dashboard/users' ? primaryColor : '#757575' }} />,
        },
        {
            title: 'Networks',
            link: '/admin/dashboard/networks',
            icon: <MdOutlineNetworkCheck style={{ color: location.pathname === '/admin/dashboard/networks' ? primaryColor : '#757575' }} />,
        },
        {
            title: 'Wallet',
            link: '/admin/dashboard/wallet',
            icon: <FaWallet style={{ color: location.pathname === '/admin/dashboard/wallet' ? primaryColor : '#757575' }} />,
        },
        {
            title: 'Wallet Transactions',
            link: '/admin/dashboard/wallet-transactions',
            icon: <GrTransaction style={{ color: location.pathname === '/admin/dashboard/wallet-transactions' ? primaryColor : '#757575' }} />,
        },
        {
            title: 'Settings',
            link: '/admin/dashboard/settings',
            icon: <MdSettings style={{ color: location.pathname === '/admin/dashboard/settings' ? primaryColor : '#757575' }} />,
        }
    ];

    return (
        <>
            {
                isDesktop ? (
                    <>
                        <div className="layout-container">
                            <div className="sidebar-container">
                                <Sidebar dashboard_items={dashboard_items} />
                            </div>
                            <div className="content-container" style={{ paddingTop: '1%' }}>
                                <Routes>
                                    <Route element={<DashboardComp />} path="/" />
                                    <Route element={<Technicians />} path="/technicians" />
                                    <Route element={<CreateTechnicians />} path="/create_technicion" />
                                    <Route element={<Users />} path="/users" />
                                    <Route element={<CreateUsers />} path="/create_users" />
                                    <Route element={<SimCards />} path="/sim-cards" />
                                    <Route element={<Networks />} path="/networks" />
                                    <Route element={<Wallet />} path="/wallet" />
                                    <Route element={<WalletTransaction />} path="/wallet-transactions" />
                                    <Route element={<Settings />} path="/settings" />
                                </Routes>
                            </div>
                        </div>
                    </>
                ) : (
                    <>This Web App is not made for small screen devices</>
                )
            }
        </>
    )
}