// import * as React from 'react';
// import Backdrop from '@mui/material/Backdrop';
// import Box from '@mui/material/Box';
// import Modal from '@mui/material/Modal';
// import Fade from '@mui/material/Fade';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import { Autocomplete, Grid, TextField } from '@mui/material';
// import { useState, useEffect } from 'react';
// import { Toaster, toast } from 'react-hot-toast';
// import { primaryColor } from '../constant';
// import { get_data, post_data } from '../api';
// import dayjs, { Dayjs } from 'dayjs';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     border: '1px solid #fff',
//     borderRadius: 3,
//     boxShadow: 24,
//     p: 4,
// };

// export default function WalletTRModal({ data, isUpdate, open, setOpen, getAllWalletTR }) {
//     const [loading, setLoading] = useState(false);
//     const [userList, setUserList] = useState([]);
//     const [formData, setFormData] = useState({
//         user_id: "",
//         transaction_id: "",
//         amount: "",
//         type: "",
//         transaction_for: "",
//     });
//     const [errors, setErrors] = useState({});

//     useEffect(() => {
//         if (isUpdate && data) {
//             setFormData({
//                 transaction_id: data?.transaction_id || '',
//                 user_id: data?.user_id?._id || '',
//                 amount: data?.amount || '',
//                 type: data?.type || '',
//                 transaction_for: data?.transaction_for || ''
//             });
//         }
//     }, [data, isUpdate]);

//     const handleError = (error, label) => {
//         setErrors((prev) => ({ ...prev, [label]: error }));
//     };

//     // Validation for form fields
//     const validation = () => {
//         let error = false;

//         if (!formData?.amount || parseFloat(formData.amount) <= 0) {
//             error = true;
//             handleError('Please input a valid amount', 'amount');
//         }
//         if (!formData?.user_id) {
//             error = true;
//             handleError('Please select a user', 'user_id');
//         }
//         if (!formData?.transaction_id) {
//             error = true;
//             handleError('Please input Transaction Id', 'transaction_id');
//         }
//         if (!formData?.transaction_for) {
//             error = true;
//             handleError('Please input Transaction for', 'transaction_for');
//         }
//         if (!formData?.type) {
//             error = true;
//             handleError('Please input Type', 'type');
//         }

//         return error;
//     };

//     // Reset form and errors on modal close
//     const handleClose = () => {
//         setOpen(false);
//         setErrors({});
//     };

//     const handleSubmit = async () => {
//         const hasError = validation();
//         if (!hasError) {
//             setLoading(true);
//             try {
//                 let response;
//                 if (isUpdate) {
//                     response = await post_data(`wallet-transaction/update-wallet-transaction/${data?._id}`, formData);
//                 } else {
//                     response = await post_data('wallet-transaction/create-wallet-transaction', formData);
//                 }

//                 if (response?.status === true) {
//                     toast.success(response?.message || 'Operation successful!');
//                     setLoading(false);
//                     getAllWalletTR(); // Refresh the list
//                     handleClose(); // Close modal after successful operation
//                 } else {
//                     toast.error(response?.message || 'Something went wrong!');
//                     setLoading(false);
//                 }
//             } catch (error) {
//                 setLoading(false);
//                 toast.error('An error occurred. Please try again.');
//             }
//         }
//     };

//     useEffect(() => {
//         const fetchUsers = async () => {
//             try {
//                 const response = await get_data('users/get-all-users');
//                 setUserList(response?.data?.users || []);
//             } catch (error) {
//                 console.error("Error fetching users:", error);
//             }
//         };
//         fetchUsers();
//     }, []);

//     const handleChange = (field, value) => {
//         setFormData((prev) => {
//             const updatedFormData = { ...prev, [field]: value };
//             // Clear error when the field is updated
//             handleError('', field);
//             return updatedFormData;
//         });
//     };

//     return (
//         <div>
//             <Modal
//                 aria-labelledby="transition-modal-title"
//                 aria-describedby="transition-modal-description"
//                 open={open}
//                 onClose={handleClose}
//                 closeAfterTransition
//                 slots={{ backdrop: Backdrop }}
//                 slotProps={{
//                     backdrop: {
//                         timeout: 500,
//                     },
//                 }}
//             >
//                 <Fade in={open}>
//                     <Box sx={style}>
//                         <Typography id="modal-title" variant="h6" component="h2">
//                             {isUpdate ? 'Edit Wallet Transaction' : 'Create Wallet Transaction'}
//                         </Typography>
//                         <Grid container spacing={2} style={{ marginTop: 10 }}>
//                             <Grid item xs={12}>
//                                 <Autocomplete
//                                     id="user-select"
//                                     options={userList}
//                                     value={userList?.find((c) => c._id === formData?.user_id) || null}
//                                     onChange={(e, newValue) => handleChange('user_id', newValue?._id || '')}
//                                     autoHighlight
//                                     getOptionLabel={(option) => option.name}
//                                     renderInput={(params) => <TextField {...params} label="Select user" fullWidth />}
//                                 />
//                                 {errors.user_id && (
//                                     <Typography color="error" variant="body2">{errors.user_id}</Typography>
//                                 )}
//                             </Grid>

//                             <Grid item xs={12}>
//                                 <TextField
//                                     error={Boolean(errors.transaction_id)}
//                                     helperText={errors.transaction_id}
//                                     onFocus={() => handleError('', 'transaction_id')}
//                                     value={formData?.transaction_id}
//                                     label="Transaction Id"
//                                     onChange={(e) => handleChange('transaction_id', e.target.value)}
//                                     fullWidth
//                                 />
//                             </Grid>

//                             <Grid item xs={12}>
//                                 <TextField
//                                     error={Boolean(errors.amount)}
//                                     helperText={errors.amount}
//                                     onFocus={() => handleError('', 'amount')}
//                                     value={formData?.amount}
//                                     label="Amount"
//                                     onChange={(e) => handleChange('amount', e.target.value)}
//                                     fullWidth
//                                 />
//                             </Grid>
//                             <Grid item xs={12}>
//                                 <TextField
//                                     error={Boolean(errors.type)}
//                                     helperText={errors.type}
//                                     onFocus={() => handleError('', 'type')}
//                                     value={formData?.type}
//                                     label="Transaction Type"
//                                     onChange={(e) => handleChange('type', e.target.value)}
//                                     fullWidth
//                                 />
//                             </Grid>
//                             <Grid item xs={12}>
//                                 <LocalizationProvider dateAdapter={AdapterDayjs}>
//                                     <DemoContainer components={['TimePicker', 'TimePicker']}>
//                                         <TimePicker
//                                             label="Controlled picker"
//                                             value={dayjs(formData?.time || '')}
//                                             onChange={(newValue) => handleChange('time', newValue)}
//                                         />
//                                     </DemoContainer>
//                                 </LocalizationProvider>
//                             </Grid>
//                             <Grid item xs={12}>
//                                 <TextField
//                                     error={Boolean(errors.transaction_for)}
//                                     helperText={errors.transaction_for}
//                                     onFocus={() => handleError('', 'transaction_for')}
//                                     value={formData?.transaction_for}
//                                     label="Transaction For"
//                                     onChange={(e) => handleChange('transaction_for', e.target.value)}
//                                     fullWidth
//                                 />
//                             </Grid>

//                             <Grid item xs={12} style={{ display: 'flex', gap: 10 }}>
//                                 <Button
//                                     variant="outlined"
//                                     style={{
//                                         textTransform: 'capitalize',
//                                         color: primaryColor,
//                                         border: `1px solid ${primaryColor}`,
//                                         borderRadius: 0,
//                                     }}
//                                     fullWidth
//                                     onClick={handleClose}
//                                 >
//                                     Cancel
//                                 </Button>

//                                 <Button
//                                     type="submit"
//                                     style={{
//                                         textTransform: 'capitalize',
//                                         color: '#fff',
//                                         background: primaryColor,
//                                         borderRadius: 0,
//                                     }}
//                                     onClick={handleSubmit}
//                                     disabled={loading}
//                                     fullWidth
//                                 >
//                                     {loading ? 'Saving...' : isUpdate ? 'Edit' : 'Save'}
//                                 </Button>
//                             </Grid>
//                         </Grid>
//                     </Box>
//                 </Fade>
//             </Modal>
//         </div>
//     );
// }


import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import { Toaster, toast } from 'react-hot-toast';
import { primaryColor } from '../constant';
import { get_data, post_data } from '../api';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #fff',
    borderRadius: 3,
    boxShadow: 24,
    p: 4,
};

export default function WalletTRModal({ data, isUpdate, open, setOpen, getAllWalletTR }) {
    const [loading, setLoading] = useState(false);
    const [userList, setUserList] = useState([]);
    const [formData, setFormData] = useState({
        user_id: "",
        transaction_id: "",
        amount: "",
        type: "",
        transaction_for: "",
        time: null, // Added field for time picker
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (isUpdate && data) {
            setFormData({
                transaction_id: data?.transaction_id || '',
                user_id: data?.user_id?._id || '',
                amount: data?.amount || '',
                type: data?.type || '',
                transaction_for: data?.transaction_for || '',
                time: data?.time ? dayjs(data?.time) : null, // Set time from data if exists
            });
        }
    }, [data, isUpdate]);

    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };

    // Validation for form fields
    const validation = () => {
        let error = false;

        if (!formData?.amount || parseFloat(formData.amount) <= 0) {
            error = true;
            handleError('Please input a valid amount', 'amount');
        }
        if (!formData?.user_id) {
            error = true;
            handleError('Please select a user', 'user_id');
        }
        if (!formData?.transaction_id) {
            error = true;
            handleError('Please input Transaction Id', 'transaction_id');
        }
        if (!formData?.transaction_for) {
            error = true;
            handleError('Please input Transaction for', 'transaction_for');
        }
        if (!formData?.type) {
            error = true;
            handleError('Please input Type', 'type');
        }
        if (!formData?.time) {
            error = true;
            handleError('Please select a time', 'time');
        }

        return error;
    };

    // Reset form and errors on modal close
    const handleClose = () => {
        setOpen(false);
        setErrors({});
        setFormData({
            user_id: '',
            transaction_id: '',
            amount: '',
            type: '',
            transaction_for: '',
            time: null,
        });
    };

    const handleSubmit = async () => {
        const hasError = validation();
        if (!hasError) {
            setLoading(true);
            try {
                let response;
                if (isUpdate) {
                    response = await post_data(`wallet-transaction/update-wallet-transaction/${data?._id}`, formData);
                } else {
                    response = await post_data('wallet-transaction/create-wallet-transaction', formData);
                }

                if (response?.status === true) {
                    toast.success(response?.message || 'Operation successful!');
                    setLoading(false);
                    getAllWalletTR(); // Refresh the list
                    handleClose(); // Close modal after successful operation
                } else {
                    toast.error(response?.message || 'Something went wrong!');
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                toast.error('An error occurred. Please try again.');
            }
        }
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await get_data('users/get-all-users');
                setUserList(response?.data?.users || []);
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        };
        fetchUsers();
    }, []);

    const handleChange = (field, value) => {
        setFormData((prev) => {
            const updatedFormData = { ...prev, [field]: value };
            // Clear error when the field is updated
            handleError('', field);
            return updatedFormData;
        });
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography id="modal-title" variant="h6" component="h2">
                            {isUpdate ? 'Edit Wallet Transaction' : 'Create Wallet Transaction'}
                        </Typography>
                        <Grid container spacing={2} style={{ marginTop: 10 }}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="user-select"
                                    options={userList}
                                    value={userList?.find((c) => c._id === formData?.user_id) || null}
                                    onChange={(e, newValue) => handleChange('user_id', newValue?._id || '')}
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params} label="Select user" fullWidth />}
                                />
                                {errors.user_id && (
                                    <Typography color="error" variant="body2">{errors.user_id}</Typography>
                                )}
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.transaction_id)}
                                    helperText={errors.transaction_id}
                                    onFocus={() => handleError('', 'transaction_id')}
                                    value={formData?.transaction_id}
                                    label="Transaction Id"
                                    onChange={(e) => handleChange('transaction_id', e.target.value)}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.amount)}
                                    helperText={errors.amount}
                                    onFocus={() => handleError('', 'amount')}
                                    value={formData?.amount}
                                    label="Amount"
                                    onChange={(e) => handleChange('amount', e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.type)}
                                    helperText={errors.type}
                                    onFocus={() => handleError('', 'type')}
                                    value={formData?.type}
                                    label="Transaction Type"
                                    onChange={(e) => handleChange('type', e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['TimePicker', 'TimePicker']}>
                                        <TimePicker
                                            label="Select Time"
                                            value={formData?.time || dayjs()}
                                            onChange={(newValue) => handleChange('time', newValue)}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.transaction_for)}
                                    helperText={errors.transaction_for}
                                    onFocus={() => handleError('', 'transaction_for')}
                                    value={formData?.transaction_for}
                                    label="Transaction For"
                                    onChange={(e) => handleChange('transaction_for', e.target.value)}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} style={{ display: 'flex', gap: 10 }}>
                                <Button
                                    variant="outlined"
                                    style={{
                                        textTransform: 'capitalize',
                                        color: primaryColor,
                                        border: `1px solid ${primaryColor}`,
                                        borderRadius: 0,
                                    }}
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    type="submit"
                                    style={{
                                        textTransform: 'capitalize',
                                        color: '#fff',
                                        background: primaryColor,
                                        borderRadius: 0,
                                    }}
                                    onClick={handleSubmit}
                                    disabled={loading}
                                    fullWidth
                                >
                                    {loading ? 'Saving...' : isUpdate ? 'Edit' : 'Save'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
