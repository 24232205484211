import { Button, Grid } from "@mui/material";
import { IoMdAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import SearchComponent from "../../components/SearchComponent"
import EmptyPage from "../../components/EmptyPage";
import TechnicianTable from "../../components/tables/TechnicianTable";
import { createButtonColor, primaryColor } from "../../constant";
import SimCardsTable from "../../components/tables/SimCardsTable";
import SimCardModel from "../../modals/SimCardModel";
import * as XLSX from 'xlsx';
import { baseURL, get_data, post_data } from "../../api";


export default function SimCards() {

    const page = {
        width: '100%',
        marginBottom: '2%'
    };

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [refresh, setRefresh] = useState(false)
    const [value, setValue] = useState('')
    const [open, setOpen] = useState(false);
    const [fileName, setFileName] = useState('');
    const [excelData, setExcelData] = useState([]);
    const [excelLoading, setExcelLoading] = useState(false);
    const getAllSimCards = async () => {
        try {
            const data = await get_data(`sim-card/get-all-sim-cards?pageNumber=${currentPage}`);
            if (data.status === true) {
                setLoading(false);
                setData(data?.data?.simCards);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalSimCards);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (currentPage) {
            getAllSimCards(currentPage);
        }
    }, [currentPage, refresh])

    const handleSearch = async () => {
        setLoading(true);
        if (value.length > 0) {
            const data = await get_data(`sim-card/search-sim-card/${value}?pageNumber=${currentPage}`)

            if (data.status === true) {
                setData(data?.data?.simCards);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalSimCards);
            }
        } else {
            getAllSimCards(currentPage);
        }
        setLoading(false);
    }

    const itemsPerPage = 15;
    const count = data?.length;


    const tableHeader = [
        "S No.", 'Sim Number', "msisdn_number", "Sim Type", 'Network Provider', 'Network Connection', 'Purchased Date', "Expiration Date"
    ]

    const handleCreate = () => {
        setOpen(true)
    }


    const downloadCSV = () => {
        const userAgent = window.navigator.userAgent;
        let fileType = '';
        let fileName = '';

        if (userAgent.includes('Mac')) {
            fileType = 'numbers';
            fileName = 'Sim_Card.numbers';
        } else if (userAgent.includes('Windows')) {
            fileType = 'xlsx';
            fileName = 'Sim_Card.xlsx';
        } else {
            fileType = 'xlsx';
            fileName = 'Sim_Card.xlsx';
        }

        const link = document.createElement('a');
        link.href = `${baseURL}/images/${fileName}`;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setFileName(file.name);
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet);

            setExcelData(jsonData);
        };

        reader.readAsArrayBuffer(file);
    };

    const handleSubmitExcel = async () => {
        try {
            setExcelLoading(true);
            const result = await post_data("sim-card/create-sim-card-by-excel", excelData);
            if (result.status === true) {
                toast.success("Sim Card uploaded Successfully");
                setExcelData([]);
                setFileName('');
                getAllSimCards(currentPage);
            } else {
                toast.error(result?.response?.data?.message || "Something went wrong");
            }
            setExcelLoading(false);
        } catch (error) {
        }
    };

    return (
        <>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <div style={{ display: 'flex', marginBottom: '1%', width: '100%' }}>
                    <div>
                        <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                    </div>

                    <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                        {
                            excelData.length === 0 &&
                            <>
                                <Button
                                    onClick={downloadCSV}
                                    variant='outlined'
                                    sx={{
                                        borderColor: primaryColor,
                                        color: primaryColor,
                                        textTransform: 'capitalize',
                                        borderRadius: 0,
                                        fontWeight: 400,
                                        boxShadow: 'none',
                                        '&:hover': {
                                            borderColor: primaryColor,
                                            boxShadow: 'none',
                                        },
                                        '&:focus': {
                                            outline: 'none',
                                            boxShadow: 'none',
                                            borderColor: primaryColor,
                                        }
                                    }}
                                >
                                    Download Excel
                                </Button>
                                <Button
                                    variant="contained"
                                    component="label"
                                    sx={{
                                        background: primaryColor,
                                        color: 'white',
                                        borderRadius: 0,
                                        fontWeight: 400,
                                        textTransform: 'capitalize',
                                        boxShadow: 'none',
                                        '&:hover': {
                                            backgroundColor: primaryColor,
                                            boxShadow: 'none',
                                        },
                                        '&:focus': {
                                            outline: 'none',
                                            boxShadow: 'none',
                                            backgroundColor: primaryColor,
                                        },
                                    }}
                                >
                                    Upload Excel
                                    <input
                                        type="file"
                                        accept=".xlsx, .xls"
                                        hidden
                                        onChange={handleFileUpload}
                                    />
                                </Button>
                            </>
                        }
                        {fileName && <p>Uploaded File: {fileName}</p>}

                        {excelData.length > 0 && (
                            <Button
                                variant="contained"
                                sx={{
                                    background: primaryColor,
                                    color: 'white',
                                    borderRadius: 0,
                                    fontWeight: 400,
                                    textTransform: 'capitalize',
                                    boxShadow: 'none',
                                    '&:hover': {
                                        backgroundColor: primaryColor,
                                        boxShadow: 'none',
                                    },
                                    '&:focus': {
                                        outline: 'none',
                                        boxShadow: 'none',
                                        backgroundColor: primaryColor,
                                    }
                                }}
                                disabled={excelLoading}
                                onClick={handleSubmitExcel}
                            >
                                {excelLoading ? 'Uploading...' : "Submit"}
                            </Button>
                        )}
                    </div>

                    <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                        <Button
                            onClick={handleCreate}
                            startIcon={<IoMdAdd style={{ color: 'white' }} />}
                            variant="contained"
                            style={{ borderRadius: 5, background: createButtonColor, boxShadow: 'none' }}
                        >
                            Create
                        </Button>
                        <SimCardModel isUpdate={false} setOpen={setOpen} getAllSimCards={getAllSimCards} open={open} />
                    </div>

                </div>

                <SimCardsTable
                    data={data}
                    tableHeader={tableHeader}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    count={count}
                    totalData={totalData}
                    totalPages={totalPages}
                    loading={loading}
                    setLoading={setLoading}
                    getAllSimCards={getAllSimCards}
                    refresh={refresh} setRefresh={setRefresh}
                />
                {/* </> */}
                {/*  } */}

            </Grid>

        </>
    )
}