import './App.css';
import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { serverURL, get_data, post_data } from './api';
import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { login } from './redux/slices/user-slice';
import { Toaster } from 'react-hot-toast';
import Home from "./website/pages/Home"
import Dashboard from './admin-dashboard/pages/Dashboard';
import Login from './admin-dashboard/pages/Login';
import ChangeEmail from './components/ChangeEmail';

function App() {

  const dispatch = useDispatch();
  const { isLoggedIn, user_data } = useSelector(state => state.user);

  const token = localStorage.getItem("authToken");

  const get_user = async () => {
    const current_data = await get_data("user/get-user", { headers: { "Authorization": token } });
    if (current_data?.status) {
      dispatch(login(current_data?.data));
    } else {
    }
  };

  useEffect(() => {
    get_user();
  }, []);

  const [screenLoading, setScreenLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setScreenLoading(false)
    }, 2000);
  }, [])


  return (
    <div className="App">
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
      {
        screenLoading ?
          <>
          </>
          :
          <>
            <Router>
              <Routes>
                <Route element={<Home />} path="/" />
                <Route element={<Login />} path="/admin/login" />
                <Route element={<ChangeEmail />} path="/admin/change-email" />
                {/* <Route element={token ? <Dashboard /> : <Login />} path="/company/dashboard/*" /> */}
                <Route element={<Dashboard />} path="/admin/dashboard/*" />

              </Routes>
            </Router>
          </>
      }
    </div>
  );
}

export default App;