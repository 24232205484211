import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { useState, useEffect } from "react";
import { createButtonBg, primaryColor } from '../constant';
import { get_data, post_data } from '../api';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
};

export default function EmailModel({ emailModel, setEmailModel, isEmail, refresh, setRefresh }) {

    const { user_data } = useSelector(state => state.user);
    const [email, setEmail] = useState(user_data?.email || '');
    const [phone, setPhone] = useState(user_data?.phone || '');
    const [errors, setErrors] = useState({});
    const [brandList, setBrandList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [brandId, setBrandId] = useState('');

    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };

    const validation = () => {
        let error = false;
        if (isEmail ? !email : !phone) {
            error = true;
            isEmail ? handleError('Please input Email...', 'email') : handleError('Please input Phone Number...', 'phone');
        }

        return error;
    };

    const handleClose = () => {
        if (isEmail) {
            setEmailModel(false);
        } else {
            setEmailModel(false);
        }
    };

    const handleSubmit = async () => {
        const error = validation();
        if (!error) {
            try {
                let data;
                if (isEmail) {
                    data = await post_data(`model/update-model/${user_data?._id}`, { phone, email });
                    setEmailModel(false);
                } else {
                    data = await post_data(`model/create-model`, { email, phone });
                    setEmailModel(false);
                }
                setRefresh(!refresh);
                if (data?.status) {
                    toast.success(data?.message || 'Operation successful!');
                } else {
                    toast.error(data?.message || 'Operation failed!');
                }
            } catch (error) {
                toast.error('An error occurred. Please try again.');
            }
        }
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={emailModel}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={emailModel}>
                    <Box sx={style}>
                        <Typography id="modal-title" variant="h6" component="h2">
                            {isEmail ? 'Change Email' : 'Change Number'}
                        </Typography>
                        <Grid container spacing={2} style={{ marginTop: 10 }}>
                            {isEmail ? <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.email)}
                                    helperText={errors.email}
                                    onFocus={() => handleError('', 'email')}
                                    value={email}
                                    label="Email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    fullWidth
                                />
                            </Grid> : <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.phone)}
                                    helperText={errors.phone}
                                    onFocus={() => handleError('', 'phone')}
                                    value={phone}
                                    label="Phone"
                                    onChange={(e) => setPhone(e.target.value)}
                                    fullWidth
                                />
                            </Grid>}


                            <Grid item xs={12} style={{ display: 'flex', gap: 10 }}>
                                <Button
                                    variant="outlined"
                                    style={{
                                        textTransform: 'capitalize',
                                        color: primaryColor,
                                        border: `1px solid ${primaryColor}`,
                                        borderRadius: 0,
                                    }}
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    style={{
                                        textTransform: 'capitalize',
                                        color: '#fff',
                                        background: primaryColor,
                                        borderRadius: 0,
                                    }}
                                    onClick={handleSubmit}
                                    fullWidth
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
