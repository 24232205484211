import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Typography,
    Pagination,
    Switch,
    CircularProgress,
    Menu,
    MenuItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Swal from 'sweetalert2';
import { post_data } from '../../api';
import { useNavigate } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';
import { primaryColor } from '../../constant';
import TableLoader from '../TableLoader';
import UserDetailsModal from '../../modals/UserDetailsModal';
import WalletModel from '../../modals/WalletModel';

const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: '7px 10px',
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid gainsboro',
        },
    },
    moreIcon: {
        color: '#a3a3a3',
    },
});

const WalletTable = ({
    data,
    tableHeader,
    getAllWallets,
    setLoading,
    totalData,
    loading,
    currentPage,
    setCurrentPage,
    itemsPerPage,
    totalPages,
}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [rowsData, setRowsData] = useState(data || []);
    const [open, setOpen] = useState(false);
    const [editData, setEditData] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);
    const [switchLoaders, setSwitchLoaders] = useState({});

    useEffect(() => { setRowsData(data) }, [data])
    const handleMenuOpen = (event, index) => {
        setAnchorEl(event.currentTarget);
        setCurrentRow(index);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setCurrentRow(null);
    };

    const handleDetals = (row) => {
        // setEditData(row);
        // setOpen(true);
        // handleMenuClose();
    };

    const handleEdit = (row) => {
        setEditData(row);
        setOpen(true);
        handleMenuClose();
    };

    const handleDelete = async (row) => {
        handleMenuClose();

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: primaryColor,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const data = await post_data(`wallet/delete-wallet/${row?._id}`, {});
                    if (data.status) {
                        toast.success("Wallet Deleted Successfully");
                        getAllWallets();
                    } else {
                        toast.error("Something Went Wrong");
                    }
                } catch (error) {
                    toast.error("Error deleting user");
                }
            }
        });
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalData);

    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            <TableContainer style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro' }}>
                <Table className={classes.root}>
                    <TableHead>
                        <TableRow>
                            {tableHeader?.map((item, idx) => (
                                <TableCell
                                    key={idx}
                                    style={{
                                        borderRight: '1px solid gainsboro',
                                        padding: '16px 10px',
                                        width: idx === 0 ? '5%' : '20%',
                                    }}
                                >
                                    {item}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    {loading ? (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={tableHeader.length} style={{ textAlign: 'center' }}>
                                    <TableLoader />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ) : (
                        <TableBody>
                            {rowsData?.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ borderRight: '1px solid gainsboro', textAlign: 'center', padding: '0px', width: '5%' }}>
                                        {startEntry + index}
                                    </TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.user_id?.name || '-'}</TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.user_id?.role || '-'}</TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.balance || '-'}</TableCell>

                                    <TableCell style={{ borderRight: '1px solid gainsboro', width: '5%' }}>
                                        <IconButton className={classes.moreIcon} onClick={(event) => handleMenuOpen(event, index)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu anchorEl={anchorEl} open={Boolean(anchorEl) && currentRow === index} onClose={handleMenuClose}>
                                            {/* <MenuItem onClick={() => handleDetals(row)}>View Details</MenuItem> */}
                                            <MenuItem onClick={() => handleEdit(row)}>Edit</MenuItem>
                                            <MenuItem onClick={() => handleDelete(row)}>Delete</MenuItem>
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <WalletModel open={open} setOpen={setOpen} isUpdate={true} getAllWallets={getAllWallets} data={editData} />
                        </TableBody>
                    )}
                </Table>
                {rowsData?.length > 0 && (
                    <div style={{ borderTop: '1px solid gainsboro', padding: '2%', display: 'flex', justifyContent: 'space-between' }}>
                        <Typography style={{ fontWeight: 500, fontSize: 15, color: 'black' }}>
                            {`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}
                        </Typography>
                        <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} color="primary" />
                    </div>
                )}
            </TableContainer>
        </>
    );
};

export default WalletTable;
