import React from "react";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { primaryColor } from "../constant";

export default function Sidebar({
    dashboard_items,
}) {
    const navigate = useNavigate();
    const { user_data } = useSelector(state => state.user);

    const user_dashboard_sidebar = {
        background: 'white',
        width: '100%',
        height: '100vh',
        position: 'relative',
        borderRight: '1px solid gainsboro',
        top: 0,
        display: 'flex',
        flexDirection: 'column',
    };

    const logo_section = {
        padding: '4% 5% 0',
        display: "flex",
        gap: 10,
        alignItems: 'center',
    };

    const sidebar_items_div = {
        padding: '2% 4%',
    };

    const handleListItem = (item, i) => {
        if (item?.link) {
            navigate(item.link);
            window.scrollTo(0, 0);
        }
    };

    let current_path = window.location.pathname;

    const filtered_menu_items = user_data?.isSubAdmin
        ? dashboard_items?.filter(item => item?.title !== 'Sub Admins' && item?.title !== 'Permissions')
        : dashboard_items;

    const display_sidebar_items = (arr) => {
        return (
            <List sx={{ width: '100%', maxWidth: 360 }} component="nav">
                {arr?.map((item, i) => {
                    return (
                        <div key={i}>
                            <ListItemButton
                                onClick={() => handleListItem(item, i)}
                                style={{
                                    padding: '6%',
                                    borderRadius: 8,
                                    margin: '3% 0',
                                    background: item?.type !== 'dropdown' ? current_path === item.link ? '#E9F1F3' : 'transparent' : ''
                                }}
                            >
                                <div style={{
                                    display: "flex",
                                    gap: '8%',
                                    width: '100%',
                                    alignItems: item?.type !== 'dropdown' ? "center" : 'center'
                                }}>
                                    {item.icon}
                                    <p style={{ color: current_path === item?.link ? primaryColor : '#757575', fontWeight: 400, fontSize: 15, margin: 0, padding: 0 }}>
                                        {item?.type !== 'dropdown' ? item?.title : ''}
                                    </p>
                                </div>
                            </ListItemButton>
                        </div>
                    );
                })}
            </List>
        );
    };


    return (
        <>
            <div style={user_dashboard_sidebar} className="sidebar-container">
                <div style={logo_section}>
                    <img src='https://images.jdmagicbox.com/quickquotes/images_main/oredo-gps-server-support-387096748-y1sxg.png'
                        style={{ width: 60, cursor: 'pointer' }}
                    />
                </div>
                <div style={sidebar_items_div}>
                    {display_sidebar_items(filtered_menu_items)}
                </div>
            </div>
        </>
    );
}
