export const primaryColor = "#034A74"
export const createButtonColor = '#007e59'

export const formatPrice = (value) => {
    const formatted_price = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    })?.format(value || 0);
    return formatted_price
}

export const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const year = date?.getFullYear();
    let month = date?.getMonth() + 1;
    let day = date?.getDate();


    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    const outputDate = `${day}-${month}-${year}`;
    return outputDate;
};


export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
