export default function EmptyPage() {
    return (
        <>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                margin: 'auto',
            }}>
                <center>
                    {/* <img
                        style={{ width: 350, height: 350 }}
                        src="/images/empty.svg"
                    /> */}
                    <h2 style={{
                        fontWeight: 500,
                        fontSize: 25,
                    }}>
                        Nothing to show...
                    </h2>
                </center>
            </div>
        </>
    )
}