import { Box, Button, Modal } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import * as XLSX from "xlsx";
import EmptyPage from "../components/EmptyPage";
import { formatDate, primaryColor } from "../constant";

const WalletTransactionModal = ({ exportOpen, setExportOpen, data, fromDate, toDate }) => {

    const contentRef = useRef(null);

    const styles = {
        invoice: {
            padding: "4%",
            height: 470,
        },
        header: {
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #000",
            paddingBottom: "10px",
            marginBottom: "5%",
        },
        brand: {
            textAlign: "left",
        },
        info: {
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "10px",
            paddingBottom: "10px",
        },
        infoSection: {
            textAlign: "left",
        },
        table: {
            width: "100%",
            borderCollapse: "collapse",
        },
        th: {
            border: "1px solid #ddd",
            padding: "8px",
        },
        td: {
            border: "1px solid #ddd",
            padding: "8px",
        },
        footer: {
            marginTop: "20px",
            borderTop: "2px solid #000",
            marginTop: "5%",
            paddingTop: "10px",
            display: "flex",
            justifyContent: "space-between",
        },
        terms: {
            textAlign: "left",
        },
        payment: {
            textAlign: "left",
        },
        summary: {
            marginTop: "20px",
            textAlign: "right",
        },
        text: {
            fontSize: 14,
        },
    };

    const styleModal = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 900,
        maxHeight: '90%',
        borderRadius: 2,
        overflowY: 'auto',
        bgcolor: "white",
        color: 'black',
        display: 'flex',
        flexDirection: 'column',
        border: "none",
        boxShadow: 24,
        padding: '0 0 5%',
    };

    const button = {
        padding: '5px',
        fontSize: 13,
        color: '#fff',
        backgroundColor: primaryColor,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '15%',
        marginTop: '20px',
        boxShadow: 'none',
        marginLeft: '2%',
    };

    // const handleDownloadPDF = () => {
    //     html2canvas(contentRef.current, { scale: 2 }).then((canvas) => {
    //         const imgData = canvas.toDataURL("image/png");
    //         const pdf = new jsPDF();
    //         const pageHeight = pdf.internal.pageSize.height;
    //         const imgHeight = (canvas.height * 208) / canvas.width;
    //         let heightLeft = imgHeight;

    //         // Add the image to PDF
    //         pdf.addImage(imgData, "PNG", 0, 0, 208, imgHeight);

    //         // Check if the image fits the page
    //         while (heightLeft >= pageHeight) {
    //             heightLeft -= pageHeight;
    //             pdf.addPage();
    //             pdf.addImage(imgData, "PNG", 0, heightLeft, 208, imgHeight);
    //         }

    //         pdf.save("wallet-record.pdf");  // Save the PDF
    //     });
    // };


    const handleDownloadPDF = async () => {
        if (contentRef.current) {
            const pdf = new jsPDF("p", "mm", "a4");
            const pageWidth = 210; // A4 page width in mm
            const pageHeight = 295; // A4 page height in mm
            const margin = 10;
            const tableStartY = 20;
            const rowHeight = 10; // Estimate row height
            const rowsPerPage = Math.floor((pageHeight - tableStartY - margin) / rowHeight);

            // Clone the data to paginate through it
            const dataClone = [...data];
            let positionY = tableStartY;
            let pageNumber = 1;

            // Render each page
            while (dataClone.length > 0) {
                const currentPageData = dataClone.splice(0, rowsPerPage);
                // Add table headers
                pdf.setFontSize(12);
                pdf.text("Name.", margin, positionY);
                pdf.text("Role", margin + 40, positionY);
                pdf.text("msisdn Number", margin + 70, positionY);
                pdf.text("Wallet Balance", margin + 110, positionY);
                pdf.text("Date", margin + 140, positionY);

                positionY += rowHeight;

                // Add rows for the current page
                currentPageData.forEach((item, i) => {
                    pdf.setFontSize(10);
                    pdf.text(item?.user_id?.name, margin, positionY);
                    pdf.text(item?.user_id?.role || "-", margin + 40, positionY);
                    pdf.text(item?.msisdn_number + "(Boosted AD)", margin + 70, positionY);
                    pdf.text(item?.balance || "", margin + 110, positionY);
                    pdf.text(formatDate(item?.created_at) || "", margin + 140, positionY);

                    positionY += rowHeight;
                });

                // Add a new page if there are more records to render
                if (dataClone.length > 0) {
                    pdf.addPage();
                    positionY = tableStartY;
                    pageNumber++;
                }
            }

            pdf.save("Wallet.pdf");
        }
    };

    return (
        <>
            <Modal
                open={exportOpen}
                onClose={() => setExportOpen(false)}
            >
                <Box sx={styleModal}>
                    {
                        data?.length === 0 ? (
                            <EmptyPage />
                        ) : (
                            <>
                                <Button variant="contained" onClick={handleDownloadPDF} style={button}>Download PDF</Button>
                                <div ref={contentRef} id="invoice" style={styles.invoice}>
                                    <div style={styles.header}>
                                        <div style={styles.brand}>
                                            <h2 style={{ fontWeight: 600, margin: 0 }}>Wallet's Details</h2>
                                        </div>
                                        <h2 style={{ fontWeight: 600, margin: 0 }}>Wallet Record</h2>
                                    </div>

                                    <table style={{ ...styles.table, ...styles.text, margin: "3% 0" }}>
                                        <thead style={{ textAlign: "left" }}>
                                            <tr>
                                                <th>Name</th>
                                                <th>Role</th>
                                                <th>msisdn Number</th>
                                                <th> Wallet Balance</th>
                                                <th> Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data?.map((item, i) => (
                                                    <tr key={i} style={{ margin: 0, borderBottom: '1px solid gainsboro' }}>
                                                        <td style={{ padding: '1% 0' }}>{item?.user_id?.name || '-'}</td>
                                                        <td style={{ padding: '1% 0' }}>{item?.user_id?.role || '-'}</td>
                                                        <td style={{ padding: '1% 0' }}>{item?.msisdn_number || '-'}</td>
                                                        <td style={{ padding: '1% 0' }}>{item?.balance || '-'}</td>
                                                        <td style={{ padding: '1% 0' }}>{formatDate(item?.created_at)}</td>

                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                    <div style={{ ...styles.footer, ...styles.text }}>
                                        <div style={styles.terms}>
                                            <p>All Invoices are from {formatDate(fromDate)} to {formatDate(toDate)} period.</p>
                                            <p>For M2M</p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </Box>
            </Modal>
        </>
    );
};

export default WalletTransactionModal;
