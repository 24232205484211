import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Table, TableHead, TableBody, TextField, Divider } from '@mui/material';
import { formatDate, formatPrice } from '../constant';
import { post_data, serverURL } from '../api';

const UserDetailsModal = ({ open, setOpen, data, getAllUsers }) => {

    const handleClose = () => {
        setOpen(false);
    };


    const styles = {
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 550,
            height: '70vh',
            border: 'none',
            bgcolor: 'white',
            borderRadius: '10px',
            boxShadow: 12,
            textAlign: 'center',
            overflow: 'auto',
            borderRadius: 3
        },
        heading: {
            fontWeight: 600,
            fontSize: 27,
            marginBottom: '10px',
        },
        row: {
            display: 'flex',
            width: '100%',
            gap: '10px',
            margin: '4% 0',
            fontWeight: 400,
            fontSize: 16,
        },
        dataLabel: {
            fontWeight: 400,
            fontSize: 16,
            width: '50%',
            textAlign: 'left'
        },
        dataValue: {
            fontWeight: 400,
            fontSize: 16,
            marginLeft: 'auto',
            width: '50%',
            textAlign: 'right'
        },
        icon: {
            width: 22,
            marginRight: 5,
        },
        headingTypography: {
            fontWeight: 500,
            fontSize: 22,
            marginBottom: '10px',
            textAlign: 'left',
            p: 3,
            borderBottom: '1px solid gainsboro'
        }
    };

    const address = data?.address
        ? `${data?.address?.street}, ${data?.address?.city}, ${data?.address?.zipCode}, ${data?.address?.state}, ${data?.address?.country}`
        : '-';

    const DataRow = ({ label, value }) => (
        <div style={styles.row}>
            <span style={styles.dataLabel}>{label}</span>
            <span style={styles.dataValue}>{value}</span>
        </div>
    );


    const handleUpdateSimMobileNo = async (e, id) => {
        // if (e.key === 'Enter') {
        //     const result = await post_data(`product/update-product/${id}`, { simMobileNo: e.target.value });
        //     if (result?.status === true) {
        //         toast.success("Sim Mobile No. Updated Successfully");
        //         getAllUsers();
        //         handleClose();
        //     } else {
        //         toast.error("Something went wrong");
        //     }
        // }
    };
    console.log("xxxxxxxxxxxx", data)
    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={styles.modalBox}>
                <Typography id="logout-modal-title" variant="h6" sx={styles.headingTypography}>
                    User&apos;s Details
                </Typography>
                <Box sx={{ padding: '2% 6% 6% 6%' }}>
                    <DataRow label="User UniqueId" value={data?.uniqueUserId || '-'} />
                    <DataRow label="User Name" value={data?.name || '-'} />
                    <DataRow label="Email" value={data?.email || '-'} />
                    <DataRow label="Phone Number" value={data?.phone_number || '-'} />
                    <DataRow label="Address" value={address || '-'} />
                    <DataRow label="Status" value={data?.is_active ? "Active" : "Not-Active" || '-'} />
                    <Divider />
                    <DataRow label="Documents" value={data?.documents?.map((item) => <img src={`${serverURL}/uploads/m2m/user-documents/${item?.file}`} style={styles.icon} alt={`${item?.name}`} />)} />
                </Box >
            </Box >
        </Modal >
    );
};

export default UserDetailsModal;
