import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Autocomplete, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useState, useEffect } from "react";
import toast from 'react-hot-toast';
import { City, Country, State } from 'country-state-city';
import { primaryColor } from '../constant';
import { post_data } from '../api';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '1px solid #fff',
    boxShadow: 24,
    p: 4,
    height: 500,
    overflow: 'auto',
    borderRadius: 3,
    ////////// MANAGE CROLLBAR ///////////
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
        width: '6px',
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888',
        borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#555',
    },
};

export default function CreateNetworksModal({ setOpen, open, editData, isUpdate, getAllNetworks }) {
    const [errors, setErrors] = useState({});
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [countryList, setCountryList] = useState([])
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        network_connection: '',
        contact_person: {
            phone_number: '',
            email: '',
            country: '',
            state: '',
            city: '',
            street: '',
            zip_code: ''
        }
    })

    useEffect(() => {
        setCountryList(Country.getAllCountries());
    }, []);

    useEffect(() => {
        if (formData?.contact_person?.country && formData?.contact_person?.state) {
            const fetchedState = State.getStatesOfCountry(formData?.contact_person?.country)
            const fetchedCities = City.getCitiesOfState(formData?.contact_person?.country, formData?.contact_person?.state);
            setStateList(fetchedState)
            setCityList(fetchedCities);
        }
    }, [formData?.contact_person?.state, formData?.contact_person?.country]);

    const handleCountryChange = (event, newValue) => {
        if (newValue) {
            setFormData({ ...formData, contact_person: { ...formData?.contact_person, country: newValue?.isoCode } })
            const fetchedStates = State.getStatesOfCountry(newValue.isoCode || formData?.contact_person?.country);
            setStateList(fetchedStates);
        }
    }

    const handleStateChange = (event, newValue) => {
        if (newValue) {
            setFormData({ ...formData, contact_person: { ...formData?.contact_person, state: newValue?.isoCode } })
            const fetchedCities = City.getCitiesOfState(formData?.contact_person?.country, newValue.isoCode || formData?.contact_person?.state);
            setCityList(fetchedCities);
        }
    };

    const handleCityChange = (event, newValue) => {
        if (newValue) {
            setFormData({ ...formData, contact_person: { ...formData?.contact_person, city: newValue?.name } })
        }
    };

    useEffect(() => {
        // Initialize form state based on whether it is an update or a new entry
        if (isUpdate && editData) {
            // console.log("{...formData,name:editModelData?.name}",editModelData)
            setFormData({
                ...formData,
                name: editData?.name,
                network_connection: editData?.network_connection,
                contact_person: {
                    country: editData?.contact_person?.country,
                    street: editData?.contact_person?.street,
                    state: editData?.contact_person?.state,
                    city: editData?.contact_person?.city,
                    zip_code: editData?.contact_person?.zip_code,
                    phone_number: editData?.contact_person?.phone_number,
                    email: editData?.contact_person?.email
                }
            })

        } else {
            setFormData({ ...formData })
        }
    }, [editData, isUpdate]);

    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };

    const validation = () => {
        let error = false;
        if (!formData?.name) {
            error = true;
            handleError('Please input Name...', 'name');
        }
        if (!formData?.network_connection) {
            error = true;
            handleError('Please input network type...', 'network_connection');
        }
        if (!formData?.contact_person?.city) {
            error = true;
            handleError('Please select city...', 'city');
        }
        if (!formData?.contact_person?.country) {
            error = true;
            handleError('Please select country...', 'country');
        }
        if (!formData?.contact_person?.email) {
            error = true;
            handleError('Please enter...', 'email');
        }
        if (!formData?.contact_person?.phone_number) {
            error = true;
            handleError('Please enter phone number...', 'phone');
        }
        if (!formData?.contact_person?.state) {
            error = true;
            handleError('Please select state...', 'state');
        }
        if (!formData?.contact_person?.street) {
            error = true;
            handleError('Please enter street...', 'street');
        }
        if (!formData?.contact_person?.zip_code) {
            error = true;
            handleError('Please ener zip code...', 'zipCode');
        }
        return error;
    };

    const handleClose = () => {
        setFormData('')
        setOpen(false)
    };

    const handleSubmit = async () => {
        const error = validation();
        if (!error) {
            setLoading(true);
            try {
                let data;
                if (isUpdate) {
                    data = await post_data(`network/update-network/${editData?._id}`, { ...formData });
                } else {
                    data = await post_data(`network/create-network`, { ...formData });
                }
                if (data?.status === true) {
                    toast.success(data?.message || 'Operation successful!');
                    setFormData(''); // Clear form data on success
                    getAllNetworks();
                    setOpen(false);
                } else {
                    toast.error(data?.message || 'Operation failed!');
                }
            } catch (error) {
                toast.error('An error occurred. Please try again.');
            } finally {
                setLoading(false); // Reset loading state
            }
        }
    };

    console.log("formData: ", formData)
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography id="modal-title" variant="h6" component="h2">
                            {isUpdate ? 'Edit Networks' : 'Create Networks'}
                        </Typography>
                        <Grid container spacing={2} style={{ marginTop: 10 }}>

                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.name)}
                                    helperText={errors.name}
                                    onFocus={() => handleError('', 'name')}
                                    value={formData?.name}
                                    label="Name"
                                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                    fullWidth
                                    type='text'
                                />
                            </Grid>

                            <Grid item xs={12} >
                                <FormControl fullWidth variant="outlined" >
                                    <InputLabel id="network-select-label">Network Type</InputLabel>
                                    <Select
                                        labelId="network-select-label"
                                        id="network-select"
                                        value={formData?.network_connection}
                                        onChange={(e) => setFormData({ ...formData, network_connection: e.target.value })}
                                        label="Network Type"
                                        error={Boolean(errors.network_connection)}
                                        helperText={errors.network_connection}
                                        onFocus={() => handleError('', 'network_connection')}
                                    >
                                        <MenuItem value="2G">2G</MenuItem>
                                        <MenuItem value="3G">3G</MenuItem>
                                        <MenuItem value="4G">4G</MenuItem>
                                        <MenuItem value="5G">5G</MenuItem>
                                    </Select>
                                    {errors.network_connection ? <div style={{ color: '#D32F2F', fontSize: '.8rem' }}>{errors.network_connection}</div> : <></>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component="h1">Contact Person</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.email)}
                                    helperText={errors.email}
                                    onFocus={() => handleError('', 'email')}
                                    value={formData?.contact_person?.email}
                                    label="Email"
                                    onChange={(e) => setFormData({ ...formData, contact_person: { ...formData?.contact_person, email: e.target.value } })}
                                    fullWidth
                                    type='email'
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.phone)}
                                    helperText={errors.phone}
                                    onFocus={() => handleError('', 'phone')}
                                    value={formData?.contact_person?.phone_number}
                                    label="Phone Number"
                                    onChange={(e) => setFormData({ ...formData, contact_person: { ...formData?.contact_person, phone_number: e.target.value } })}
                                    fullWidth

                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography component="h1">Address</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Street"
                                    error={Boolean(errors.street)}
                                    helperText={errors.street}
                                    onFocus={() => handleError('', 'street')}
                                    value={formData?.contact_person?.street}
                                    onChange={(e) => setFormData({ ...formData, contact_person: { ...formData?.contact_person, street: e.target.value } })}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    id="country-select"
                                    options={countryList}
                                    value={countryList.find(s => s.isoCode === formData?.contact_person?.country || '') || null}
                                    onChange={(event, newValue) => handleCountryChange(event, newValue)}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField
                                            error={!!errors?.country} helperText={errors?.country} onFocus={() => handleError('', 'country')}
                                            {...params}
                                            label="Select Country"
                                            fullWidth
                                        />
                                    )}
                                />

                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    id="state-select"
                                    options={stateList}
                                    value={stateList.find((s) => s.isoCode === formData?.contact_person?.state || '') || null}
                                    onChange={handleStateChange}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField
                                        error={Boolean(errors.state)}
                                        helperText={errors.state}
                                        onFocus={() => handleError('', 'state')}
                                        {...params} label="Select State" fullWidth />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    id="city-select"
                                    options={cityList}
                                    value={cityList.find((c) => c.name === formData?.contact_person?.city || '') || null}
                                    onChange={handleCityChange}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params}
                                        error={Boolean(errors.city)}
                                        helperText={errors.city}
                                        onFocus={() => handleError('', 'city')}
                                        label="Select City" fullWidth />}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Zip Code"

                                    value={formData?.contact_person?.zip_code}
                                    error={Boolean(errors.zipCode)}
                                    helperText={errors.zipCode}
                                    onFocus={() => handleError('', 'zipCode')}
                                    onChange={(e) => setFormData({ ...formData, contact_person: { ...formData?.contact_person, zip_code: e.target.value } })}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} style={{ display: 'flex', gap: 10 }}>
                                <Button
                                    variant="outlined"
                                    style={{
                                        textTransform: 'capitalize',
                                        color: primaryColor,
                                        border: `1px solid ${primaryColor}`,
                                        borderRadius: 0,
                                    }}
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    style={{
                                        textTransform: 'capitalize',
                                        color: '#fff',
                                        background: primaryColor,
                                        borderRadius: 0,
                                    }}
                                    onClick={handleSubmit}
                                    fullWidth
                                    disabled={loading} // Disable button when loading
                                >
                                    {loading ? 'Saving...' : isUpdate ? 'Edit' : 'Save'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
