import { Button, Grid } from "@mui/material";
import { IoMdAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { createButtonColor } from "../../constant";
import { get_data } from "../../api";
import NetworksModal from "../../modals/NetworksModal";
import NetworksTable from "../../components/tables/NetworksTable";
import EmptyPage from "../../components/EmptyPage";
import SearchComponent from "../../components/SearchComponent";

const dummyData = [{
    "name": "Example Network",
    "network_connection": "2G",
    "contact_person": {
        "phone_number": "+1-800-555-1234",
        "email": "contact@example.com",
        "country": "IN",
        "state": "MP",
        "city": "Gwalior",
        "street": "1234 Main St",
        "zip_code": "90001"
    }
}, {
    "name": "Example Network",
    "network_connection": "5G",
    "contact_person": {
        "phone_number": "+1-800-555-1234",
        "email": "contact@example.com",
        "country": "IN",
        "state": "MP",
        "city": "Gwalior",
        "street": "1234 Main St",
        "zip_code": "90001"
    }
}]
export default function Networks() {
    const page = {
        width: '100%',
        marginBottom: '2%'
    };

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [refresh, setRefresh] = useState(false)
    const [value, setValue] = useState('')

    const getAllNetworks = async () => {
        try {
            const data = await get_data(`network/get-all-networks/?pageNumber=${currentPage}`);
            if (data.status) {
                setLoading(false);
                setData(data?.data?.networks);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalNetworks);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (currentPage) {
            getAllNetworks(currentPage);
        }
    }, [currentPage, refresh])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }, []);


    const itemsPerPage = 15;
    const count = data?.length;


    const tableHeader = [
        "S No.", "Name", "Network Connection", "Phone Number", "Email", "Address"
    ]

    const handleNavigate = () => {
        setOpen(true)
    }


    // const handleSearch = async () => {
    //     setLoading(true);
    //     if (value.length > 0) {
    //         const data = await get_data(`network/search-network/${value}?pageNumber=${currentPage}`)
    //         console.log(data)
    //         if (data.status === true) {
    //             setData(data?.data?.networks);
    //             setTotalPages(data?.data?.totalPages);
    //             setTotalData(data?.data?.totalNetworks);
    //         }
    //     } else {
    //         getAllNetworks(currentPage);
    //     }
    //     setLoading(false);
    // }

    return (
        <>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <div style={{ display: 'flex', marginBottom: '1%', width: '100%' }}>
                    {/* <div>
                        <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                    </div> */}
                    <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                        <Button
                            onClick={handleNavigate}
                            startIcon={<IoMdAdd style={{ color: 'white' }} />}
                            variant="contained"
                            style={{ borderRadius: 5, background: createButtonColor, boxShadow: 'none', }}
                        >
                            Create
                        </Button>
                        <NetworksModal open={open} setOpen={setOpen} isUpdate={false} getAllNetworks={getAllNetworks} />
                    </div>

                </div>


                {/* {
                    data?.length === 0 ?
                        <>
                            <EmptyPage />
                        </>
                        :
                        <> */}
                <NetworksTable
                    data={data}
                    tableHeader={tableHeader}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    count={count}
                    totalData={totalData}
                    totalPages={totalPages}
                    loading={loading}
                    setLoading={setLoading}
                    getAllNetworks={getAllNetworks}
                    refresh={refresh} setRefresh={setRefresh}
                />
                {/* </>
                } */}
            </Grid>
        </>
    )
}