import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Menu,
    MenuItem,
    IconButton,
    Avatar,
    Typography,
    Pagination,
    CircularProgress,
    Switch,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Swal from 'sweetalert2';
import { post_data, serverURL } from '../../api'; // Ensure serverURL is correctly imported
import { useNavigate } from 'react-router-dom';
import EmptyPage from '../EmptyPage';
import { Toaster, toast } from 'react-hot-toast';
import { primaryColor, createButtonColor } from '../../constant';
import TableLoader from '../TableLoader';
import TechnicianDetailsModal from '../../modals/TechnicianDetailsModal';


const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: '7px 10px',
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid gainsboro',
        },
    },
    stageButton: {
        padding: '4px 12px',
        borderRadius: '20px',
        fontSize: '12px',
        fontWeight: 'bold',
        '&.closedOwn': {
            backgroundColor: '#e8f5e9',
            color: '#43a047',
        },
        '&.analysis': {
            backgroundColor: '#fff3e0',
            color: '#fb8c00',
        },
        '&.qualification': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
        '&.closedLost': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        },
        '&.true': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
    },
    moreIcon: {
        color: '#a3a3a3',
    },
});

const TechnicianTable = ({ data, tableHeader, getAllTechnicians, setLoading, totalData, loading, currentPage, setCurrentPage, itemsPerPage, totalPages, refresh, setRefresh }) => {
    // console.log('dummyData', data)
    const classes = useStyles();
    const navigate = useNavigate();
    const [rowsData, setRowsData] = useState(data || []);
    const [open, setOpen] = useState(false);
    const [editData, setEditData] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);
    const [switchLoaders, setSwitchLoaders] = useState({});

    const handleCheckboxToggle = (index) => {
        const updatedRows = [...rowsData];
        updatedRows[index].checked = !updatedRows[index].checked;
        setRowsData(updatedRows);
    };

    // Open menu on click of more options icon
    const handleMenuOpen = (event, index) => {
        setAnchorEl(event.currentTarget);
        setCurrentRow(index);
    };

    // Close the options menu
    const handleMenuClose = (row) => {
        setAnchorEl(null);
        setCurrentRow(null);
    };

    const handleDetails = (row) => {
        setEditData(row)
        setOpen(true)
        setAnchorEl(null);
        setCurrentRow(null);
    };
    const handleEdit = (row) => {
        navigate('/admin/dashboard/create_technicion', { state: { data: row, title: 'update' } })
        setAnchorEl(null);
        setCurrentRow(null);
    };
    // Delete a product after confirmation
    const handleDelete = async (row) => {
        setAnchorEl(null);
        setCurrentRow(null);

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: primaryColor,
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const data = await post_data(`users/delete-user/${row?._id}`, {})
                    if (data.status) {
                        toast.success("Technician Deleted Successfully");
                        getAllTechnicians();
                    }
                    else {
                        toast.error("Something Went Wrong");
                    }
                } catch (error) {
                }
            }
        });

    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };
    const handleSwitch = async (item) => {
        setSwitchLoaders((prev) => ({ ...prev, [item?._id]: true }));
        try {
            const payload = { is_active: !item?.is_active };
            const data = await post_data(`users/update-user/${item?._id}`, payload);
            if (data.status === true) {
                toast.success('Technician updated Successfully');
                getAllTechnicians()
            } else {
                toast.error('Something Went Wrong');
            }
        } catch (error) {
            toast.error('Error updating user');
        }
        setSwitchLoaders((prev) => ({ ...prev, [item?._id]: false }));
    };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalData);

    return (
        <>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            <TableContainer style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro' }}>
                <Table className={classes.root}>
                    <TableHead>
                        <TableRow>
                            {tableHeader?.map((item, idx) => (
                                <TableCell
                                    key={idx}
                                    style={{
                                        borderRight: '1px solid gainsboro',
                                        padding: '16px 10px',
                                        width: idx === 0 ? '5%' : '20%', // Adjust widths as needed
                                    }}
                                >
                                    {item}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    {loading ? (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={tableHeader.length} style={{ textAlign: 'center' }}>
                                    <TableLoader />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ) : (
                        <TableBody>
                            {data?.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ borderRight: '1px solid gainsboro', textAlign: 'center', padding: '0px', width: '5%', }} > {startEntry + index} </TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro', }}>  {row?.name || '-'} </TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro' }}>{`${row?.address?.street || " "} ${row?.address?.city || " "}  ${row?.address?.state || " "} ${row?.address?.country || ""} ${row?.address?.zip_code}` || '-'}</TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro', }}>  {row?.phone_number || '-'} </TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro', }}>  {row?.email || '-'} </TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.sim_cards?.length || 0} Sim Cards </TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro', width: '3%' }}>
                                        {switchLoaders[row?._id] ? (
                                            <CircularProgress size={20} />
                                        ) : (
                                            <Switch onChange={() => handleSwitch(row)} checked={row?.is_active} />
                                        )}
                                    </TableCell>
                                    <TableCell style={{ borderRight: '1px solid gainsboro', }}>
                                        <IconButton
                                            className={classes.moreIcon}
                                            onClick={(event) => handleMenuOpen(event, index)}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>

                                        {/* Options menu */}
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl) && currentRow === index}
                                            onClose={handleMenuClose}
                                        >
                                            <MenuItem onClick={() => handleDetails(row)}>View Details</MenuItem>
                                            <MenuItem onClick={() => handleEdit(row)}>Edit</MenuItem>
                                            <MenuItem onClick={() => handleDelete(row)}>Delete</MenuItem>
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TechnicianDetailsModal open={open} setOpen={setOpen} data={editData} getAllTechnicians={getAllTechnicians} />
                        </TableBody>

                    )}
                </Table>
                {data?.length > 0 && (
                    <div
                        style={{
                            borderTop: '1px solid gainsboro',
                            padding: '2%',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography
                            style={{
                                fontWeight: 500,
                                fontSize: 15,
                                color: 'black',
                            }}
                        >{`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}</Typography>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </div>
                )}
            </TableContainer>

        </>
    );
};

export default TechnicianTable;
