import { TextField, Grid, Switch, Checkbox, FormControlLabel, Button, IconButton, FormGroup, Typography, Autocomplete, FormHelperText, Table, TableHead, TableBody, TableRow, TableCell, FormControl, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import { formatDate, formatPrice, primaryColor } from '../constant';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import ImageIcon from '@mui/icons-material/Image';
import { Country, State, City } from 'country-state-city';
import Swal from 'sweetalert2';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { post_data, serverURL, get_data } from '../api';
import AdminChangPasswordModals from '../modals/AdminChangPasswordModals';


const label = { inputProps: { 'aria-label': 'Size switch demo' } };

const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
});

export default function CreateTechnicians() {
    // const { isLoggedIn, user_data } = useSelector(state => state.user);
    const location = useLocation()
    const Data = location?.state?.data
    const title = location?.state?.title
    const [cityList, setCityList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [errors, setErrors] = useState({});
    const [loader, setLoader] = useState(false);
    const [newDocuments, setNewDocuments] = useState([])
    const [compressedImages, setCompressedImages] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfrimPassword, setShowConfrimPassword] = useState(false);
    const [simCardList, setSimCardList] = useState([]);
    const [isImageUpdate, setIsImageUpdate] = useState('');
    const [images, setImages] = useState([]);
    const [uploadNewImage, setUploadNewImage] = useState([]);


    const [formData, setFormData] = useState({
        name: '', email: '', phone_number: '', is_active: false,
        address: { country: '', state: '', city: '', street: '', zip_code: '' },
        role: 'technician',
        sim_cards: []
    });

    const navigate = useNavigate()

    useEffect(() => {
        if (title === 'update' && Data) {
            setFormData({
                name: Data?.name,
                email: Data?.email,
                phone_number: Data?.phone_number,
                app_pin: Data?.app_pin,
                address: {
                    country: Data?.address?.country,
                    state: Data?.address?.state,
                    city: Data?.address?.city,
                    street: Data?.address?.street,
                    zip_code: Data?.address?.zip_code
                },
                sim_cards: Data?.sim_cards?.map((item) => item?.sim_card_id) || [],
                documents: Data?.documents?.map((item) => item?.file) || [],
            })
            setNewDocuments(Data?.documents?.map((item) => item?.file) || [])
        }
    }, [title, Data]);

    const handleInputChange = (field, value) => {
        if (field === 'street' || field === 'zip_code') {
            setFormData({ ...formData, address: { ...formData.address, [field]: value } });
        } else {
            setFormData({ ...formData, [field]: value });
        }
    };

    const handleError = (error, label) => {
        setErrors((prev) => {
            const updatedErrors = { ...prev };
            if (!updatedErrors) {
                updatedErrors = {};
            }
            updatedErrors[label] = error;
            return updatedErrors;
        });
    };

    const validation = () => {
        let hasError = false;
        if (!formData?.name) {
            handleError('Please Enter', 'name');
            hasError = true;
        }
        if (!formData?.email) {
            handleError('Please Enter', 'email');
            hasError = true;
        }
        if (!formData?.phone_number) {
            handleError('Please Enter phone_number', 'phone_number');
            hasError = true;
        }
        // if (!formData?.app_pin) {
        //     handleError('Please Enter app_pin', 'app_pin');
        //     hasError = true;
        // }
        // if (!formData?.address?.country) {
        //     handleError('Please select country', 'country');
        //     hasError = true;
        // }
        // if (!formData?.address?.state) {
        //     handleError('Please Select state', 'state');
        //     hasError = true;
        // }
        // if (!formData?.address?.city) {
        //     handleError('Please Select', 'city');
        //     hasError = true;
        // }
        // if (!formData?.address?.street) {
        //     handleError('Please Enter street', 'street');
        //     hasError = true;
        // }
        // if (!formData?.address?.zip_code) {
        //     handleError('Please Enter zip_code', 'zip_code');
        //     hasError = true;
        // }
        // if (title === 'update') {
        //     if (!formData?.password) {
        //         handleError('Please Enter password', 'password');
        //         hasError = true;
        //     }
        //     if (!formData?.confirmPassword) {
        //         handleError('Please Enter confirmPassword', 'confirmPassword');
        //         hasError = true;
        //     }
        //     if (formData?.confirmPassword != formData?.password) {
        //         alert('Check Password')
        //         hasError = true;
        //     }
        // }

        return hasError;
    };
    console.log("formdataformdata", newDocuments)

    const handleUpdate = async (event) => {
        event.preventDefault();

        if (!validation()) {
            setLoader(true);

            if (uploadNewImage?.length > 0 || isImageUpdate) {
                const body = { ...formData }
                var payload = new FormData();
                Object.keys(body).forEach((key) => {
                    if (key === 'documents') {

                        const updateImageURL = [];

                        newDocuments?.forEach((image) => {
                            if (image instanceof File) {
                                payload.append('document', image);
                            }
                            else if (typeof image === 'string') {
                                updateImageURL.push(image);
                            }
                        })

                        payload.append('updateImageURL', JSON.stringify(updateImageURL));

                    } else if (key === 'address' && typeof formData[key] === 'object') {
                        Object.keys(formData[key]).forEach((addressKey) => {
                            payload.append(`address[${addressKey}]`, formData[key][addressKey]);
                        });
                    } else if (key === 'sim_cards') {
                        const simCardList = body[key].map((simCard) => {
                            return {
                                sim_card_id: simCard,
                                type: 'active'
                            }
                        })
                        payload.append(key, JSON.stringify(simCardList));
                    } else {
                        payload.append(key, body[key]);
                    }
                })


                const result = await post_data(`users/update-user-with-images/${Data?._id}`, payload);

                if (result?.status === true) {
                    toast.success("Technician Update Successfully");
                    navigate('/admin/dashboard/technicians');
                } else {
                    toast.error(result?.response?.data?.message || "Something Went Wrong");
                }
                setLoader(false);
            } else {
                const simCardList = formData?.sim_cards.map((simCard) => {
                    return {
                        sim_card_id: simCard,
                        type: 'active'
                    }
                })

                const result = await post_data(`users/update-user/${Data?._id}`, { ...formData, sim_cards: simCardList });

                if (result?.status === true) {
                    toast.success("Technician Update Successfully");
                    navigate('/admin/dashboard/technicians');
                } else {
                    toast.error(result?.response?.data?.message || "Something Went Wrong");
                }
                setLoader(false);
            }
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validation()) {
            setLoader(true);

            const body = { ...formData }
            var payload = new FormData();
            Object.keys(body).forEach((key) => {
                if (key === 'documents') {
                    body.documents.forEach((document) => {
                        payload.append('document', document);
                    });
                } else if (key === 'address' && typeof formData[key] === 'object') {
                    Object.keys(formData[key]).forEach((addressKey) => {
                        payload.append(`address[${addressKey}]`, formData[key][addressKey]);
                    });
                } else if (key === 'sim_cards') {
                    const simCardList = body[key].map((simCard) => {
                        return {
                            sim_card_id: simCard,
                            type: 'active'
                        }
                    })
                    payload.append(key, JSON.stringify(simCardList));
                } else {
                    payload.append(key, body[key]);
                }
            });

            let respons
            {
                title === 'update' ? respons = await post_data(`users/update-user/${Data?._id}`, payload)
                    :
                    respons = await post_data(`users/create-user`, payload)
            }
            if (respons.status === true) {
                toast.success("technician Register Successfully");
                navigate("/admin/dashboard/technicians");
                setLoader(false)
            }
            else {
                toast.error(respons?.response?.data?.message || "Something Went Wrong")
                setLoader(false)
            }
        }
    }

    const buttonStyles = {
        borderColor: primaryColor,
        color: 'white',
        background: primaryColor,
        textTransform: 'capitalize',
        borderRadius: 0,
        fontWeight: 400,
        padding: '1% 3.5%',
        boxShadow: 'none',
        '&:hover': {
            borderColor: primaryColor,
            boxShadow: 'none',
        },
        '&:focus': {
            outline: 'none',
            boxShadow: 'none',
            borderColor: primaryColor,
        }
    };
    //////////////////////COUNTRY LIST///////////////////////

    const fetchSimCard = async () => {
        const response = await get_data('sim-card/get-all-sim-card-for-options')
        if (response.status === true) {
            setSimCardList(response.data)
        }
    }

    useEffect(function () {
        setCountryList(Country.getAllCountries());
        fetchSimCard();
    }, [])

    const handleCountryChange = (event, newValue) => {
        if (newValue) {
            const countryCode = newValue.isoCode;
            const fetchedStates = State.getStatesOfCountry(countryCode);
            setStateList(fetchedStates);
            setCityList([]);
            setFormData({ ...formData, address: { ...formData?.address, country: countryCode, state: '', city: '' } });
        }
    };

    const handleStateChange = (event, newValue) => {
        if (newValue) {
            const stateCode = newValue.isoCode;
            const countryCode = formData?.address?.country;
            const fetchedCities = City.getCitiesOfState(countryCode, stateCode);
            setCityList(fetchedCities);
            setFormData({ ...formData, address: { ...formData?.address, state: stateCode, city: '' } });
        }
    };

    const handleCityChange = (event, newValue) => {
        if (newValue) {
            setFormData({ ...formData, address: { ...formData?.address, city: newValue.name } });
        }
    };

    ///////////////////////////////////////////////////////////////////

    /////////////////// AUTOFILL COUNTRY , STATE , CITY //////////////////
    useEffect(() => {
        if (formData?.address?.country) {
            const fetchedStates = State.getStatesOfCountry(formData?.address?.country);
            setStateList(fetchedStates);
        }
    }, [formData?.address?.country]);

    useEffect(() => {
        if (formData?.address?.state) {
            const fetchedCities = City.getCitiesOfState(formData?.address?.country, formData?.address?.state);
            setCityList(fetchedCities);
        }
    }, [formData?.address?.state]);
    /////////////////////////////////////////////////////////////////////


    ////////////////////////DropDown/////////////////////////////
    const dropzoneStyle = {
        border: '2px dashed ' + primaryColor,
        borderRadius: '8px',
        padding: '40px',
        textAlign: 'center',
        width: '100%',
        margin: 'auto',
        backgroundColor: '#f9f9f9',
        cursor: 'pointer',
        transition: '0.3s',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxSizing: 'border-box',
    }

    const imagesBox = {
        position: 'relative',
        width: 130,
        height: 130,
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    }


    const handleCompressImage = async () => {
        // const compressedImageArray = [];
        // for (let i = 0; i < images.length; i++) {
        //     const image = images[i];
        //     const options = {
        //         maxWidthOrHeight: 800,
        //         useWebWorker: true,
        //     };
        //     try {
        //         const compressedImage = await imageCompression(image, options);
        //         const compressedFile = new File(
        //             [compressedImage],
        //             image.name,
        //             { type: image.type, lastModified: Date.now() }
        //         );
        //         compressedImageArray.push(compressedFile);
        //     } catch (error) {
        //     }
        // }
        // if (Update) {
        //     setCompressedImages([...newImages, ...compressedImageArray]);
        // } else {
        //     setCompressedImages([...compressedImageArray]);
        // }
    };


    ///////////////////////////////////////////////////


    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfrimPassword = () => setShowConfrimPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    return (
        <Grid container spacing={3} style={{ padding: '3% 7%' }}>
            <Grid item md={12} style={{ background: '#fff', padding: '20px' }}>
                <form onSubmit={title === 'update' ? handleUpdate : handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}><Typography variant="h6" gutterBottom>{title === 'update' ? 'Update' : 'Create'} Technicians</Typography></Grid>

                        {/* Brand Selection */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Name"
                                name="name"
                                value={formData?.name || ''}
                                onChange={(e) => handleInputChange('name', e.target.value)}
                                fullWidth
                                error={errors?.name}
                                onFocus={() => handleError('', 'name')}
                                helperText={errors?.name}
                            />
                        </Grid>


                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Email"
                                name="email"
                                value={formData?.email || ''}
                                onChange={(e) => handleInputChange('email', e.target.value)}
                                fullWidth
                                error={errors?.email}
                                onFocus={() => handleError('', 'email')}
                                helperText={errors?.email}
                            />
                        </Grid>


                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Phone Number"
                                name="phone_number"
                                value={formData?.phone_number || ''}
                                onChange={(e) => handleInputChange('phone_number', e.target.value)}
                                fullWidth
                                error={errors?.phone_number}
                                onFocus={() => handleError('', 'phone_number')}
                                helperText={errors?.phone_number}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="App Pin"
                                name="app_pin"
                                value={formData?.app_pin || ''}
                                onChange={(e) => handleInputChange('app_pin', e.target.value)}
                                fullWidth
                            // onFocus={() => handleError('', 'app_pin')}
                            // helperText={errors?.app_pin}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                multiple
                                id="sim-select"
                                options={simCardList}
                                value={simCardList.filter(sim => formData?.sim_cards?.includes(sim?._id)) || []}
                                onChange={(event, newValue) => {
                                    setFormData({
                                        ...formData,
                                        sim_cards: newValue.map(item => item._id)
                                    });
                                }}
                                autoHighlight
                                getOptionLabel={(option) => option.sim_number || ""}
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        {...props}
                                        key={option._id}
                                    >
                                        {option.sim_number}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Sim Cards"
                                        fullWidth
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>

                        {title === 'update' ? <></> :
                            <Grid item xs={12} sm={6} style={{ display: 'flex', gap: 15 }}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>Password</InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        label="Password"
                                        name='password'
                                        placeholder="Enter Your Password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={formData?.password}
                                        onChange={(e) => handleInputChange('password', e.target.value)}
                                        error={Boolean(errors?.password)}
                                        helperText={errors?.password}
                                        onFocus={() => handleError('', 'password')}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText style={{ color: "#D32F2f" }} id="outlined-weight-helper-text">{errors?.password}</FormHelperText>
                                </FormControl>
                            </Grid>
                        }
                        {title === 'update' ? <></> : <Grid item xs={12} sm={6} style={{ display: 'flex', gap: 15 }}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel>Confirm  Password</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    label="Confirm Password"
                                    name='confirmPassword'
                                    variant="outlined"
                                    placeholder="Confrim Your Password"
                                    type={showConfrimPassword ? 'text' : 'password'}
                                    value={formData?.confirmPassword}
                                    error={Boolean(errors?.confirmPassword)}
                                    helperText={errors?.confirmPassword}
                                    onFocus={() => handleError('', 'confirmPassword')}
                                    onChange={(e) => handleInputChange('confirmPassword', e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowConfrimPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showConfrimPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText style={{ color: "#D32F2f" }}>{errors?.confirmPassword}</FormHelperText>
                            </FormControl>
                        </Grid>
                        }

                        <Grid item xs={12}><Typography variant="h6" gutterBottom>Address</Typography></Grid>
                        <Grid item xs={12} sm={4}>
                            <Autocomplete
                                id="country-select"
                                options={countryList}
                                value={countryList.find(c => c.isoCode === formData?.address?.country) || null}
                                onChange={handleCountryChange}
                                autoHighlight
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        {...props}
                                        value={option.isoCode}
                                        key={option.isoCode}
                                    >
                                        {option.name}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Country"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>

                        {/* Owner Name */}
                        <Grid item xs={12} sm={4}>
                            <Autocomplete
                                id="state-select"
                                options={stateList}
                                value={stateList.find(s => s.isoCode === formData?.address?.state) || null}
                                onChange={handleStateChange}
                                autoHighlight
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        {...props}
                                        value={option.isoCode}
                                        key={option.isoCode}
                                    >
                                        {option.name}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select State"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>

                        {/* Owner Contact Number */}
                        <Grid item xs={12} sm={4}>
                            <Autocomplete
                                id="city-select"
                                options={cityList}
                                value={cityList.find(c => c.name === formData?.address?.city) || null}
                                onChange={handleCityChange}
                                autoHighlight
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        {...props}
                                        value={option.name}
                                        key={option.name}
                                    >
                                        {option.name}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select City"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Street"
                                name="street"
                                value={formData?.address?.street || ''}
                                onChange={(e) => handleInputChange('street', e.target.value)}
                                fullWidth
                                onFocus={() => handleError('', 'street')}
                                helperText={errors?.street}
                            />

                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Zip Code"
                                name="zip_code"
                                value={formData?.address?.zip_code || ''}
                                onChange={(e) => handleInputChange('zip_code', e.target.value)}
                                fullWidth
                                onFocus={() => handleError('', 'zip_code')}
                                helperText={errors?.zip_code}
                            />
                        </Grid>
                        {title === 'update' ? <Grid item xs={12} sm={6}>
                            <AdminChangPasswordModals isUpdate={false} title={'technician'} Data={Data} />
                        </Grid> : <></>}
                        <Grid item xs={12}><Typography variant="h6" gutterBottom>Documents</Typography></Grid>

                        <Grid item md={12}>
                            <Box component="label" sx={dropzoneStyle}>
                                <input
                                    type="file"
                                    // accept="image/*"
                                    accept="application/pdf/image"
                                    multiple
                                    onChange={(e) => {
                                        const documents = Array.from(e.target.files);
                                        if (title === 'update') {
                                            setFormData({ ...formData, uploadNewImage: [...(formData.uploadNewImage || []), ...documents] });
                                            setNewDocuments([...newDocuments, ...documents]);
                                            setUploadNewImage([...documents]);
                                            handleError('', 'documents');
                                        } else {
                                            setFormData({ ...formData, documents: [...(formData.documents || []), ...documents] });
                                            setImages([...images, ...documents]);
                                            handleError('', 'documents')
                                        }
                                        e.target.value = '';
                                    }}

                                    style={{ display: 'none' }}
                                />
                                <ImageIcon sx={{ fontSize: 48, color: primaryColor }} />
                                <Typography variant="h6" sx={{ color: primaryColor, marginTop: '16px' }}>
                                    Choose your Files
                                </Typography>
                                <Typography variant="body2" sx={{ color: '#999999' }}>
                                    Accepts all Formats
                                </Typography>
                            </Box>
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: "10px", marginTop: '3%' }}>
                                {formData?.documents?.length > 0 && title === 'update' ?
                                    newDocuments?.map((image, index) => {
                                        let src;
                                        if (typeof image === 'string') {
                                            src = `${serverURL}/uploads/m2m/user-documents/${image}`;
                                        } else if (image instanceof File) {
                                            src = URL.createObjectURL(image);
                                        } else {
                                            src = '';
                                        }

                                        return <Box
                                            key={index}
                                            sx={{
                                                position: 'relative',
                                                width: '150px',
                                                height: '150px',
                                                borderRadius: '8px',
                                                overflow: 'hidden',
                                                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                                            }}
                                        >
                                            <img
                                                src={src}
                                                alt={`Uploaded preview ${index}`}
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                }}
                                            />
                                            <IconButton
                                                onClick={() => {
                                                    const updatedNewImages = newDocuments.filter((_, i) => i !== index);
                                                    setNewDocuments(updatedNewImages);
                                                    setIsImageUpdate(true);
                                                }}
                                                sx={{
                                                    position: 'absolute',
                                                    top: '4px',
                                                    right: '4px',
                                                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(255, 255, 255, 1)',
                                                    },
                                                }}
                                            >
                                                <CloseIcon sx={{ fontSize: '16px', color: '#f44336' }} />
                                            </IconButton>
                                        </Box>
                                    })
                                    :
                                    images?.map((image, index) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                position: 'relative',
                                                width: '150px',
                                                height: '150px',
                                                borderRadius: '8px',
                                                overflow: 'hidden',
                                                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                                            }}
                                        >
                                            <img
                                                src={URL.createObjectURL(image)}
                                                alt={`Uploaded preview ${index}`}
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                }}
                                            />
                                            <IconButton
                                                onClick={() => {
                                                    const updatedImages = images.filter((_, i) => i !== index);
                                                    setFormData({ ...formData, documents: updatedImages });
                                                    setImages(updatedImages);
                                                }}
                                                sx={{
                                                    position: 'absolute',
                                                    top: '4px',
                                                    right: '4px',
                                                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(255, 255, 255, 1)',
                                                    },
                                                }}
                                            >
                                                <CloseIcon sx={{ fontSize: '16px', color: '#f44336' }} />
                                            </IconButton>
                                        </Box>
                                    ))}
                            </div>
                            {/* <Box component="label" sx={dropzoneStyle} style={{ marginTop: '10px' }}>
                                <input
                                    type="file"
                                    accept="application/pdf"
                                    multiple
                                    onChange={(e) => {
                                        const pdf = Array.from(e.target.files);
                                        if (title) {
                                            setFormData({ ...formData, uploadNewImage: [...(formData.uploadNewImage || []), ...pdf] });
                                            setNewImages([...newImages, ...pdf]);
                                            // handleCompressImage(pdf)
                                        } else {
                                            setFormData({ ...formData, images: [...(formData.images || []), ...pdf] });
                                            e.target.value = ''; // Clear the input for the next upload      
                                        }
                                    }}

                                    style={{ display: 'none' }}
                                />
                                <ImageIcon sx={{ fontSize: 48, color: primaryColor }} />
                                <Typography variant="h6" sx={{ color: primaryColor, marginTop: '16px' }}>
                                    Choose your File
                                </Typography>
                                <Typography variant="body2" sx={{ color: '#999999' }}>
                                    Accepts in PDF Formats
                                </Typography>
                            </Box> */}

                            {/* <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '3%' }}>
                                {formData.images?.map((image, index) => (
                                    (image?.name ? image?.name?.includes?.('.pdf') : image?.includes?.('.pdf')) ? <Box key={index} sx={imagesBox} position="relative">

                                        {title === 'update' ? image?.includes?.('.pdf') ? <embed src={`${serverURL}/uploads/products-images/${image}`} width="100%" height="100%" type="application/pdf" /> : <></> : image?.name?.includes?.('.pdf') ? <embed src={URL.createObjectURL(image)} width="100%" height="100%" type="application/pdf" /> : <></>}

                                        <IconButton
                                            onClick={() => {
                                                const updatedImages = formData.images.filter((_, i) => i !== index);
                                                setFormData({ ...formData, images: updatedImages }); // Update formData with the filtered images
                                            }}
                                            sx={{
                                                position: 'absolute',
                                                top: '4px',
                                                right: '4px',
                                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(255, 255, 255, 1)',
                                                },
                                            }}
                                        >
                                            <CloseIcon sx={{ fontSize: '16px', color: '#f44336' }} />
                                        </IconButton>
                                    </Box> : <></>
                                ))}

                                {formData?.uploadNewImage ? formData?.uploadNewImage?.map((image, index) => {
                                    return image?.name?.includes?.('.pdf') ? <Box key={index} sx={imagesBox} position="relative">
                                        {image?.name?.includes?.('.pdf') ? <embed src={URL.createObjectURL(image)} width="100%" height="100%" type="application/pdf" /> : <></>}

                                        <IconButton
                                            onClick={() => {
                                                const updatedImages = formData.uploadNewImage.filter((_, i) => i !== index);
                                                setFormData({ ...formData, uploadNewImage: updatedImages }); // Update formData with the filtered images
                                            }}
                                            sx={{
                                                position: 'absolute',
                                                top: '4px',
                                                right: '4px',
                                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(255, 255, 255, 1)',
                                                },
                                            }}
                                        >
                                            <CloseIcon sx={{ fontSize: '16px', color: '#f44336' }} />
                                        </IconButton>
                                    </Box> : <></>
                                }) : <></>}

                            </div> */}
                            {/* {!!errors.images ? <span style={{ color: '#EA2024', fontSize: 10 }}>{errors.images}</span> : ''} */}
                        </Grid>

                        {/* Submit Button */}
                        <Grid item xs={12}>
                            <Button variant="contained" type="submit" style={buttonStyles} disabled={loader}>
                                {loader ? 'Loading...' : (title === 'update' ? 'Update' : 'Submit')}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>

    );
}


