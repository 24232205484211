import { Button, Grid } from "@mui/material";
import { IoMdAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import SearchComponent from "../../components/SearchComponent"
import EmptyPage from "../../components/EmptyPage";
import TechnicianTable from "../../components/tables/TechnicianTable";
import { createButtonColor } from "../../constant";
import UsersTable from "../../components/tables/UsersTable";
import { get_data } from "../../api";

// const dummyData = [{
//     "_id": '989498740723913775',
//     "name": "John Doe",
//     "address": {
//         'country': 'IN',
//         "street": "123 Main St",
//         "city": "Gwalior",
//         "state": "MP",
//         "zipCode": "62701"
//     },
//     "phone_number": "+1-555-123-4567",
//     "email": "johndoe@example.com",
//     "is_active": true,
//     "app_pin": "1234",
//     'sim': [{ 'sim_number': '98800800' }, { 'sim_number': '9809' }],
//     "documents": [
//         {
//             "name": "passport",
//             "file": "passport_file.pdf",
//             "issue_date": "2018-06-15",
//             "expiry_date": "2028-06-15"
//         },
//         {
//             "name": "driver_license",
//             "file": "driver_license_file.pdf",
//             "issue_date": "2020-01-01",
//             "expiry_date": "2030-01-01"
//         }
//     ]
// },
// {
//     "_id": '098202723758729575',
//     "name": "John Doe",
//     "address": {
//         'country': 'IN',
//         "street": "123 Main St",
//         "city": "Gwalior",
//         "state": "MP",
//         "zipCode": "62701"
//     },
//     "phone_number": "+1-555-123-4567",
//     "email": "johndoe@example.com",
//     'sim': [{ 'sim_number': '98800800' }, { 'sim_number': '9809' }, { 'sim_number': '9900' }],
//     "is_active": false,
//     "app_pin": "1234",
//     "documents": [
//         {
//             "name": "passport",
//             "file": "passport_file.pdf",
//             "issue_date": "2018-06-15",
//             "expiry_date": "2028-06-15"
//         },
//         {
//             "name": "driver_license",
//             "file": "driver_license_file.pdf",
//             "issue_date": "2020-01-01",
//             "expiry_date": "2030-01-01"
//         }
//     ]
// }
// ]
export default function Users() {

    const page = {
        width: '100%',
        marginBottom: '2%'
    };

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [refresh, setRefresh] = useState(false)
    const [value, setValue] = useState('')

    const getAllUsers = async () => {
        try {
            const data = await get_data(`users/get-all-users?pageNumber=${currentPage}`);
            console.log("data", data)
            if (data.status === true) {
                setLoading(false);
                setData(data?.data?.users);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalUsers);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (currentPage) {
            getAllUsers(currentPage);
        }
    }, [currentPage, refresh])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }, []);

    const handleSearch = async () => {
        setLoading(true);
        if (value.length > 0) {
            const data = await get_data(`users/search-user/${value}?pageNumber=${currentPage}`)
            // console.log("xxxxxxxxxxxx",data)
            if (data.status === true) {
                setData(data?.data?.users);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalUsers);
            }
        } else {
            getAllUsers(currentPage);
        }
        setLoading(false);
    }

    const itemsPerPage = 15;
    const count = data?.length;


    const tableHeader = [
        "S No.", ' Name', 'Address', 'Phone Number', 'Email', 'Sim Card', 'Active', // 'App Pin', 'Documents'
    ]

    const handleCreate = () => {
        navigate('/admin/dashboard/create_users')
    }

    return (
        <>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <div style={{ display: 'flex', marginBottom: '1%', width: '100%' }}>
                    <div>
                        <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                    </div>
                    <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                        <Button
                            onClick={handleCreate}
                            startIcon={<IoMdAdd style={{ color: 'white' }} />}
                            variant="contained"
                            style={{ borderRadius: 5, background: createButtonColor, boxShadow: 'none', }}
                        >
                            Create
                        </Button>
                    </div>

                </div>

                {/* {
                    data?.length === 0 ?
                        <>
                            <EmptyPage />
                        </>
                        :
                        <> */}
                <UsersTable
                    data={data}
                    tableHeader={tableHeader}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    count={count}
                    totalData={totalData}
                    totalPages={totalPages}
                    loading={loading}
                    setLoading={setLoading}
                    getAllUsers={getAllUsers}
                    refresh={refresh} setRefresh={setRefresh}
                />
                {/* </>
                } */}
            </Grid>

        </>
    )
}