import { Button, Grid } from "@mui/material";
import { IoMdAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import SearchComponent from "../../components/SearchComponent"
import { createButtonColor, } from "../../constant";
import { get_data, post_data } from "../../api";
import WalletModel from "../../modals/WalletModel";
import WalletTable from "../../components/tables/WalletTable";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import WalletTransactionModal from "../../modals/WalletTransactionModal";

const rangePickerStyle = {
    display: 'flex',
    cursor: 'pointer',
    fontSize: 14,
    background: 'white',
    padding: '0% 0% 0% 0%',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    color: '#7E8299',
    cursor: 'pointer'
};

export default function Wallet() {

    const page = {
        width: '100%',
        marginBottom: '2%'
    };

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [refresh, setRefresh] = useState(false)
    const [value, setValue] = useState('')
    const [open, setOpen] = useState(false)
    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;
    const [exportOpen, setExportOpen] = useState(false)
    const [fromDate, setFromDate] = useState([])
    const [toDate, setToDate] = useState([])
    const [vendorsData, setVendorsData] = useState([])
    // const [fileName, setFileName] = useState('');
    // const [excelData, setExcelData] = useState([]);

    const getAllWallets = async () => {
        try {
            const data = await get_data(`wallet/get-all-wallets?pageNumber=${currentPage}`);
            console.log("data", data)
            if (data.status === true) {
                setLoading(false);
                setData(data?.data?.wallets);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalWallets);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (currentPage) {
            getAllWallets(currentPage);
        }
    }, [currentPage, refresh])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }, []);

    const handleSearch = async () => {
        setLoading(true);
        if (value.length > 0) {
            const data = await get_data(`wallet/search-wallet/${value}?pageNumber=${currentPage}`)
            console.log("xxxxxxxxxxxx",data)
            if (data.status === true) {
                setData(data?.data?.wallets);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalWallets);
            }
        } else {
            getAllWallets(currentPage);
        }
        setLoading(false);
    }

    const itemsPerPage = 15;
    const count = data?.length;


    const tableHeader = [
        "S No.", "User Name", "Role",  "Wallet Balance"
    ]

    const handleCreate = () => {
        setOpen(true)
    }

    const handleDateChange = (dates) => {
        if (dates && dates?.length === 2) {
            setDateRange(dates);
        }
    };

    const fetchExportData = async (search) => {
        try {

            const fromDate = moment(startDate).toISOString();
            const toDate = moment(endDate).toISOString();

            let response = await post_data('wallet/get-wallet-by-date', {
                from: fromDate,
                to: toDate
            })
            console.log(response)
            if (response?.status == true) {
                setVendorsData(response?.data?.wallets)
                setData(response?.data?.wallets)
                setExportOpen(true)
                setFromDate(response?.data?.fromDate)
                setToDate(response?.data?.toDate)
            } else {

            }
        } catch (error) {
        }
    };

    return (
        <>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <div style={{ display: 'flex', marginBottom: '1%', width: '100%' }}>
                    <div>
                        <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                    </div>
                    <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                        <div style={{ ...rangePickerStyle, border: '1px solid gainsboro' }}>
                            <DatePicker
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                dateFormat="dd-MM-yyyy"
                                maxDate={new Date()}
                                customInput={
                                    <button style={{ border: 'none', background: '#fff', cursor: 'pointer', }}>
                                        {startDate && endDate ? `${moment(startDate).format('DD-MM-YYYY')} to ${moment(endDate).format('DD-MM-YYYY')}` : 'Select Date Range'}
                                    </button>
                                }
                            />
                        </div>
                        <Button
                            onClick={fetchExportData}
                            variant="contained"
                            style={{ borderRadius: 0, background: createButtonColor, boxShadow: 'none', padding: '1% 4%' }}
                        >
                            Export
                        </Button>

                        <Button
                            onClick={handleCreate}
                            startIcon={<IoMdAdd style={{ color: 'white' }} />}
                            variant="contained"
                            style={{ borderRadius: 5, background: createButtonColor, boxShadow: 'none', }}
                        >
                            Create
                        </Button>
                    </div>
                    <WalletModel open={open} setOpen={setOpen} isUpdate={false} getAllWallets={getAllWallets} />
                </div>

                {/* {
                    data?.length === 0 ?
                        <>
                            <EmptyPage />
                        </>
                        :
                        <> */}
                <WalletTable
                    data={data}
                    tableHeader={tableHeader}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    count={count}
                    totalData={totalData}
                    totalPages={totalPages}
                    loading={loading}
                    setLoading={setLoading}
                    getAllWallets={getAllWallets}
                    refresh={refresh} setRefresh={setRefresh}
                />

                <WalletTransactionModal
                    exportOpen={exportOpen}
                    setExportOpen={setExportOpen}
                    fromDate={fromDate}
                    toDate={toDate}
                    data={vendorsData}
                />

                {/* </>
                } */}
            </Grid>

        </>
    )
}