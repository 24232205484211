import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import { Toaster, toast } from 'react-hot-toast';
import { primaryColor } from '../constant';
import { get_data, post_data } from '../api';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #fff',
    borderRadius: 3,
    boxShadow: 24,
    p: 4,
};

export default function WalletModel({ data, isUpdate, open, setOpen, getAllWallets }) {
    const [loading, setLoading] = useState(false);
    const [userList, setUserList] = useState([]);
    const [technicianList, setTechnicianList] = useState([]);
    const [formData, setFormData] = useState({
        user_id: '',
        technician_id: '',
        msisdn_number: '',
        balance: ''
    });
    const [errors, setErrors] = useState({});

    // Set initial form data when opening the modal for update
    useEffect(() => {
        if (isUpdate && data) {
            setFormData({
                msisdn_number: data?.msisdn_number || '',
                user_id: data?.user_id._id || '',
                technician_id: data?.technician_id || '',
                balance: data?.balance || ''
            });
        }
    }, [data, isUpdate]);

    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };

    // Validation for form fields
    const validation = () => {
        let error = false;

        if (!formData?.balance || formData.balance <= 0) {
            error = true;
            handleError('Please input a valid balance', 'balance');
        }
        if (!formData?.user_id && !formData?.technician_id) {
            error = true;
            handleError('Please select any one user/technician', 'user_id');
            handleError('Please select any one user/technician', 'technician_id');
        }

        return error;
    };

    // Reset form and errors on modal close
    const handleClose = () => {
        setOpen(false);
        setErrors({});
        // setFormData({
        //     balance: '',
        //     user_id: '',
        //     msisdn_number: '',
        // });
    };

    const handleSubmit = async () => {
        const hasError = validation();
        if (!hasError) {
            setLoading(true);
            try {
                let response;
                if (isUpdate) {
                    response = await post_data(`wallet/update-wallet/${data?._id}`, formData);
                } else {
                    response = await post_data('wallet/create-wallet', formData);
                }

                if (response?.status === true) {
                    toast.success(response?.message || 'Operation successful!');
                    setLoading(false);
                    getAllWallets(); // Refresh the list
                    handleClose(); // Close modal after successful operation
                } else {
                    toast.error(response?.response?.data?.message || 'Something went wrong!');
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                toast.error('An error occurred. Please try again.');
            }
        }
    };

    const fetchUsers = async () => {
        const response = await get_data('users/get-all-users');
        setUserList(response?.data?.users || []);
    };
    const fetchTechnician = async () => {
        const response = await get_data('users/get-all-technicians');
        setTechnicianList(response?.data?.technician || []);
    };

    useEffect(() => {
        fetchUsers()
        fetchTechnician()
    }, []);

    const handleChange = (field, value) => {
        setFormData({ ...formData, [field]: value });
        if (value) {
            if (field === 'user_id' || field === 'technician_id') {
                handleError('', 'technician_id');
                handleError('', 'user_id');
            } else {
                handleError('', field);
            }
        }
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography id="modal-title" variant="h6" component="h2">
                            {isUpdate ? 'Edit Wallet ' : 'Create Wallet'}
                        </Typography>
                        <Grid container spacing={2} style={{ marginTop: 10 }}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="user-select"
                                    options={userList}
                                    value={userList?.find((c) => c._id === formData?.user_id) || null}
                                    onChange={(e, newValue) => handleChange('user_id', newValue?._id || '')}
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params} label="Select user" fullWidth />}
                                />
                                {errors.user_id && (
                                    <Typography color="error" variant="body2">{errors.user_id}</Typography>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="technician-select"
                                    options={technicianList}
                                    value={technicianList?.find((c) => c._id === formData?.technician_id) || null}
                                    onChange={(e, newValue) => handleChange('technician_id', newValue?._id || '')}
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params} label="Select Technician" fullWidth />}
                                />
                                {errors.technician_id && (
                                    <Typography color="error" variant="body2">{errors.technician_id}</Typography>
                                )}
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.balance)}
                                    helperText={errors.balance}
                                    onFocus={() => handleError('', 'balance')}
                                    value={formData?.balance}
                                    label="Balance"
                                    onChange={(e) => handleChange('balance', e.target.value)}
                                    fullWidth
                                    type="number"
                                />
                            </Grid>

                            <Grid item xs={12} style={{ display: 'flex', gap: 10 }}>
                                <Button
                                    variant="outlined"
                                    style={{
                                        textTransform: 'capitalize',
                                        color: primaryColor,
                                        border: `1px solid ${primaryColor}`,
                                        borderRadius: 0,
                                    }}
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    type="submit"
                                    style={{
                                        textTransform: 'capitalize',
                                        color: '#fff',
                                        background: primaryColor,
                                        borderRadius: 0,
                                    }}
                                    onClick={handleSubmit}
                                    disabled={loading}
                                    fullWidth
                                >
                                    {loading ? 'Saving...' : isUpdate ? 'Edit' : 'Save'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
