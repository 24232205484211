import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Autocomplete, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import { Toaster, toast } from 'react-hot-toast';
import { primaryColor } from '../constant';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { post_data } from '../api';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #fff',
    borderRadius: 3,
    boxShadow: 24,
    p: 4,
};

export default function SimCardModel({ editData, isUpdate, open, setOpen, getAllSimCards }) {
    const [loading, setLoading] = useState(false);
    const [NetworkProviderList, setNetworkProviderList] = useState([
        { name: 'VI' },
        { name: 'Airtel' },
        { name: 'Idea' },
        { name: 'Jio' },
        { name: 'BSNL' }
    ]);
    const [formData, setFormData] = useState({
        sim_number: '',
        network_provider: '',
        purchased_date: null,
        network_connection: '',
        msisdn_number: '',
        sim_type: '',
        expiration_date: ''
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (isUpdate && editData) {
            setFormData({
                sim_number: editData?.sim_number || '',
                network_provider: editData?.network_provider || '',
                purchased_date: dayjs(editData?.purchased_date) || null,
                network_connection: editData?.network_connection || '',
                msisdn_number: editData?.msisdn_number || '',
                sim_type: editData?.sim_type || '',
                expiration_date: dayjs(editData?.expiration_date) || null
            });
        } else {
            setFormData({
                sim_number: '',
                network_provider: '',
                purchased_date: null,
                network_connection: '',
                msisdn_number: '',
                sim_type: '',
                expiration_date: null
            });
        }
    }, [editData, isUpdate]);

    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };

    const validation = () => {
        let error = false;
        if (!formData?.sim_number) {
            error = true;
            handleError('Please input Sim Number...', 'sim_number');
        }
        if (!formData?.network_provider) {
            error = true;
            handleError('Please select a Network Provider', 'network_provider');
        }
        if (!formData?.purchased_date) {
            error = true;
            handleError('Please select a Purchase Date', 'purchased_date');
        }
        if (!formData?.network_connection) {
            error = true;
            handleError('Please select Network Connection', 'network_connection');
        }
        if (!formData?.msisdn_number) {
            error = true;
            handleError('Please input msisdn Number', 'msisdn_number');
        }
        if (!formData?.sim_type) {
            error = true;
            handleError('Please input Sim Type', 'sim_type');
        }
        if (!formData?.expiration_date) {
            error = true;
            handleError('Please select Expiration Date', 'expiration_date');
        }

        return error;
    };

    const handleClose = () => {
        setOpen(false);
        setErrors({});
        setFormData({
            sim_number: '',
            network_provider: '',
            purchased_date: null,
            network_connection: '',
            msisdn_number: '',
            sim_type: '',
            expiration_date: null
        });
    };

    const handleSubmit = async () => {
        const hasError = validation();
        if (!hasError) {
            setLoading(true)
            try {
                let data;
                if (isUpdate) {
                    data = await post_data(`sim-card/update-sim-card/${editData._id}`, formData);
                } else {
                    data = await post_data('sim-card/create-sim-card', formData);
                }

                if (data?.status === true) {
                    toast.success(data?.message || 'Operation successful!');
                    setFormData('');
                    getAllSimCards();
                    setLoading(false)
                    handleClose()
                } else {
                    setLoading(false)
                    toast.error('Something went wrong!');
                }
            } catch (error) {
                setLoading(false)
                toast.error('An error occurred. Please try again.');
            }
        }
    };

    const handleChange = (field, value) => {
        setFormData({ ...formData, [field]: value });
        if (value) {
            handleError('', field);
        }
    };
    console.log("xxxxxxxxxxxx", formData)
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography id="modal-title" variant="h6" component="h2">
                            {isUpdate ? 'Edit Sim Card' : 'Create Sim Card'}
                        </Typography>
                        <Grid container spacing={2} style={{ marginTop: 10 }}>
                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.sim_number)}
                                    helperText={errors.sim_number}
                                    onFocus={() => handleError('', 'sim_number')}
                                    value={formData?.sim_number}
                                    label="Sim Number"
                                    onChange={(e) => handleChange('sim_number', e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.msisdn_number)}
                                    helperText={errors.msisdn_number}
                                    onFocus={() => handleError('', 'msisdn_number')}
                                    value={formData?.msisdn_number}
                                    label="msisdn Number"
                                    onChange={(e) => handleChange('msisdn_number', e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(errors.sim_type)}
                                    helperText={errors.sim_type}
                                    onFocus={() => handleError('', 'sim_type')}
                                    value={formData?.sim_type}
                                    label="Sim Type"
                                    onChange={(e) => handleChange('sim_type', e.target.value)}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Autocomplete
                                    id="network-provider-select"
                                    options={NetworkProviderList}
                                    value={NetworkProviderList?.find((c) => c.name === formData?.network_provider) || null}
                                    onChange={(e, newValue) => handleChange('network_provider', newValue?.name || '')}
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params} label="Network Provider" fullWidth />}
                                />
                                {errors.network_provider && (
                                    <Typography color="error" variant="body2">{errors.network_provider}</Typography>
                                )}
                            </Grid>

                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Purchased Date"
                                        fullWidth
                                        error={Boolean(errors?.purchased_date)}
                                        value={formData?.purchased_date || null}
                                        onChange={(newValue) => handleChange('purchased_date', newValue)}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                    />
                                </LocalizationProvider>
                                {errors.purchased_date && (
                                    <Typography color="error" variant="body2">{errors.purchased_date}</Typography>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Expiration Date"
                                        fullWidth
                                        error={Boolean(errors?.expiration_date)}
                                        value={formData?.expiration_date || null}
                                        onChange={(newValue) => handleChange('expiration_date', newValue)}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                    />
                                </LocalizationProvider>
                                {errors.expiration_date && (
                                    <Typography color="error" variant="body2">{errors.expiration_date}</Typography>
                                )}
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth error={Boolean(errors?.network_connection)}>
                                    <InputLabel id="demo-simple-select-label">Network Connection</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={formData?.network_connection || ''}
                                        label="Network Connection"
                                        onChange={(e) => handleChange('network_connection', e.target.value)}
                                    >
                                        <MenuItem value={'2G'}>2G</MenuItem>
                                        <MenuItem value={'3G'}>3G</MenuItem>
                                        <MenuItem value={'4G'}>4G</MenuItem>
                                        <MenuItem value={'5G'}>5G</MenuItem>
                                    </Select>
                                    {errors.network_connection && (
                                        <Typography color="error" variant="body2">{errors.network_connection}</Typography>
                                    )}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} style={{ display: 'flex', gap: 10 }}>
                                <Button
                                    variant="outlined"
                                    style={{
                                        textTransform: 'capitalize',
                                        color: primaryColor,
                                        border: `1px solid ${primaryColor}`,
                                        borderRadius: 0,
                                    }}
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    type="submit"
                                    style={{
                                        textTransform: 'capitalize',
                                        color: '#fff',
                                        background: primaryColor,
                                        borderRadius: 0,
                                    }}
                                    onClick={handleSubmit}
                                    disabled={loading}
                                    fullWidth
                                >
                                    {loading ? 'Saving...' : isUpdate ? 'Edit' : 'Save'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
