import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Autocomplete, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { useState, useEffect } from "react"
import { createButtonBg, primaryColor } from '../constant';
import { get_data, post_data } from '../api'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import toast, { Toaster } from 'react-hot-toast';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
    borderRadius: '7px',
    overflow: 'auto',
};

export default function AdminChangPasswordModals({ isUpdate, Data, title }) {
    const [errors, setErrors] = useState({});
    const [password, setPassword] = useState("");
    const [open, setOpen] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const styles = {
        rightSide: {
            backgroundColor: '#fff',
            width: '100%'
        },
        input: {
            marginBottom: '10px',
        },

    };


    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };

    const validation = () => {
        let error = false;
        if (!password) {
            error = true;
            handleError('Please input Confirm Password...', 'password');
        }

        if (!confirmPassword) {
            error = true;
            handleError('Please input Confirm Password...', 'confirmPassword');
        }

        if (password !== confirmPassword) {
            error = true
            handleError('Password does not match...', 'confirmPassword')
        }

        return error;
    };

    const handleClose = () => {
        setOpen(false)
    };

    const handleSubmit = async () => {
        const error = validation();
        if (!error && password === confirmPassword) {
            setLoading(true)
            let data
            if (title === 'users') {
                data = await post_data(`users/change-password/${Data?._id}`, { currentPassword: Data?.password, newPassword: password })
            }
            if (title === 'technician') {
                data = await post_data(`users/change-password/${Data?._id}`, { currentPassword: Data?.password, newPassword: password })
            }
            if (data?.status === true) {
                toast.success(data?.message)
                setOpen(false)
                setConfirmPassword('')
                setLoading(false)
                setPassword('')
            } else {
                toast.error('Somthing Worng !')
                setLoading(false)
            }

        }
    };



    return (
        <div >
            {title === 'vendor' ? <></> : <Typography id="modal-title" variant="h6" onClick={() => setOpen(true)} style={{ fontSize: '14px', color: primaryColor, cursor: 'pointer', marginBottom: '10px' }}>
                Change Password
            </Typography>}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >

                <Fade in={open} >
                    <Box sx={style}>
                        <Typography id="modal-title" variant="h6" component="h2" style={{ fontSize: '14px', color: primaryColor, cursor: 'pointer', }}>
                            Change Password
                        </Typography>
                        <Grid container spacing={2} >

                            <Grid item xs={12}>
                                <TextField
                                    label="Password"
                                    variant="outlined"
                                    type={showPassword ? "text" : "password"}
                                    fullWidth
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                        setPasswordError("");
                                    }}
                                    error={errors?.password}
                                    helperText={errors?.password}
                                    onFocus={() => handleError('', 'password')}
                                    style={{ ...styles.input, marginTop: '3%' }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Confirm Password"
                                    variant="outlined"
                                    type={showConfirmPassword ? "text" : "password"}
                                    fullWidth
                                    value={confirmPassword}
                                    onChange={(e) => {
                                        setConfirmPassword(e.target.value);
                                        setConfirmPasswordError("");
                                    }}
                                    error={errors?.confirmPassword}
                                    helperText={errors?.confirmPassword}
                                    onFocus={() => handleError('', 'confirmPassword')}
                                    style={styles.input}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                    edge="end"
                                                >
                                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} style={{ display: 'flex', gap: 10 }}>
                                <Button variant="outlined" style={{ textTransform: 'capitalize', color: primaryColor, border: `1px solid ${primaryColor}`, borderRadius: 0 }} fullWidth onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button variant="contained"
                                    disabled={loading} style={{ textTransform: 'capitalize', color: '#fff', background: primaryColor, borderRadius: 0 }} onClick={handleSubmit} fullWidth>
                                    {/* {title==='vendor'?<></>:<></>}   */}
                                    {isUpdate ? <>{loading ? 'Edit...' : 'Edit'}</> : <>{loading ? 'Update Password...' : 'Update Password'}</>}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>

            <Toaster
                position="top-right"
                reverseOrder={false}
            />
        </div>
    );
}